import { MdOutlineEmail } from "react-icons/md"
import { RiInboxArchiveLine } from "react-icons/ri"

function OrderInfo({ order, seats, handleSendEmail, handleArchive ,searchOrder}) {
    return (
        <>
            <p>ID: {order?.orderId}</p>
            <p>Order DateTime: {order?.createdAt}</p>
            <p>Email: {order?.email}</p>
            <p>Name: {order?.name}</p>
            <p>Phone: {order?.mobileNumber}</p>
            <p>Amount: {order?.total}</p>
            <p>Transaction ID: {order?.transactionId}</p>
            <p>Event: {order?.event?.name}</p>
            <p>Show: {
                `${order?.show?.theater?.name}, ${order?.show?.hall?.name}, ${new Date(order?.show?.date).toDateString()
                } ${order?.show?.startTime}`
            }</p>
            <p>Seats: {JSON.stringify(seats)}</p>

         {
            searchOrder &&    <>
            {order?.isActive && (
                <button
                    className="archive_btn"
                    dataTip={order?.isActive ? "Archive" : "Unarchive"}
                    dataFor='Archive Order'
                    onClick={() => handleArchive(order)}
                >
                    Archive   <RiInboxArchiveLine />
                </button>
            )}

            <button
                className="archive_btn"
                onClick={() => handleSendEmail(order)}

            >
                Send Email  <MdOutlineEmail />
            </button>
        </>

         }
        </>
    )
}

export default OrderInfo