

import "./HallModal.css";


function HallModal({
  modalHeading,
  children,
  extraClass,
  setShowModalContent,
  contentStyle,
  bodyStyle,
}) {
  return (
    <div className={`hall_modal ${extraClass}`}>
      <div
        className="hall_modal_backdrop"
        onClick={() => setShowModalContent(false)}
      ></div>
      <div
        className={`hall_modal_dialog hall_modal_dialog_centered hall_modal_dialog_scrollable`}
      >
        <div className="hall_modal_content" style={{ ...contentStyle }}>
         
          <div className="hall_modal_body" style={{ ...bodyStyle }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HallModal;