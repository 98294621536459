import React from 'react';
import DropdownMenu from './DropdownMenu';
import SeatIcon from './SeatIcon';

function TableHeader({ columns, columnTypes, activeDropdown, handleColumnSelect, handleButtonStatusClick, view,setColumnTypes,setActiveDropdown,setSeats }) {
    return (
        <thead>
            {
                view ? "" : <tr>
                    <th></th>
                    {Array.from({ length: columns }, (_, colIndex) => (
                        <th key={colIndex}>
                            <div
                                style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "start", gap: "5px", justifyContent: "center" }}>
                                <button
                                    type="button"
                                    className="square-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleButtonStatusClick(
                                            "column",
                                            colIndex,
                                            activeDropdown,
                                            setActiveDropdown,
                                            columnTypes,
                                            setColumnTypes,
                                            setSeats
                                        );
                                    }}
                                >
                                    {columnTypes[colIndex] && (
                                        <span className={`status-icon ${columnTypes[colIndex]}`}>
                                            <SeatIcon status={columnTypes[colIndex]} />
                                        </span>
                                    )}
                                </button>

                                {activeDropdown.type === "column" && activeDropdown.index === colIndex && (
                                    <DropdownMenu
                                        col
                                        type="column"
                                        index={colIndex}
                                        handleSelect={handleColumnSelect} />
                                )}

                                <div
                                    className="header-label"
                                    style={{ paddingLeft: "5px" }}>
                                    {colIndex + 1}
                                </div>
                                
                            </div>
                        </th>
                    ))}
                </tr>
            }
        </thead>
    );
}

export default TableHeader;
