function Tooltip({ tooltipContent }) {
    return (
        <div
         className="tool-tip-text"
            style={{
                position: "fixed",
                width: "max-content",
                color: "white",
                backgroundColor: "black",
                borderRadius: "0.25rem",
                padding: "0.25rem",
                zIndex: 50,
            }}
        >
            {tooltipContent}
        </div>
    );
}

export default Tooltip;
