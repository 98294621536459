import { useState, useEffect, useContext } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import { ORDERS_API } from "../../../../Utilities/APIs/APIs";
// import { useSelector } from "react-redux"


function OrderItems() {
  const [orderItems, setOrderItems] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const { orderId } = useParams();

  useEffect(() => {
    async function fetchAndSetOrderItems() {
      const { data } = await axiosInstance.get(ORDERS_API + 'orderItemsFromOrder/' + orderId);
      setOrderItems(data);
    }
    fetchAndSetOrderItems();
  }, [toggleFetch, ]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Order Items (${orderItems?.length} in total)`}</h1>
          </div>
          {/* <FilterSelect filter={filter} setFilter={setFilter} /> */}
          {/* <CreateButton
            screenTopicSingular="Order"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          /> */}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Seat" />
            <CRUDth th="Price" />
            <CRUDth th="Type" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {orderItems?.map((orderItem) => (
              <CRUDTableRow key={orderItem._id}>
                <ShortTextCell text={orderItem?.seat?.seatName} />
                <ShortTextCell text={orderItem?.price} />
                <ShortTextCell text={orderItem?.seat?.status} />
                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={orderItem._id}
                    setTargetID={setTargetID}
                  />
              

                  {/* <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={order._id}
                    isArchive={order?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  /> */}

                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {/* {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showUpdateForm && `Update Order`) ||
            (showDeleteSection && `Archive Order`)
          }
        >

          {showDeleteSection && (
            <ArchiveItem
              api={"theaters/archiveTheater/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )} */}

      {/* {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order Item`}
        >
          <ViewOrder targetID={targetID} />
        </Modal>
      )} */}
    </>
  );
}

export default OrderItems;
