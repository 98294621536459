
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

import { FaStar } from 'react-icons/fa';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaHistory } from 'react-icons/fa';
import { FaThumbsUp } from 'react-icons/fa';

function EventStatusScreens() {

    return (

        <div>
            <NavCardList numOfCards={'four'}>
                <NavCard cardName={'Now Selling'} navCardLink={`/events/nowSelling`} ><FaStar /></NavCard>
                <NavCard cardName={'Upcoming'} navCardLink={`/events/upcoming`} ><FaCalendarAlt /></NavCard>
                <NavCard cardName={'Past'} navCardLink={`/events/past`} ><FaHistory /></NavCard>
                <NavCard cardName={'Vote For Bring'} navCardLink={`/events/voteToBring`} ><FaThumbsUp /></NavCard>
            </NavCardList>
        </div>


    )
}

export default EventStatusScreens