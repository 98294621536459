import {  useContext, useEffect, useState } from "react"

import Modal from "../../../Partials/Elements/Modal/Modal"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ViewCustomer from "./ViewCustomer"
import { CUSTOMER_API } from './../../../../Utilities/APIs/APIs';
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import { RiMapPinLine, RiShoppingCartLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import AppContext from "../../../../Context/AppContext"
import BackButton from "../../../Partials/Elements/BackButton/BackButton"

function Customers() {
    
    const [customers, setCustomers] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const {setIsLoading} = useContext(AppContext)
    const axiosInstance = useAxiosInstance()
    const navigate = useNavigate()

    useEffect(() => { 
        async function fetchAndSetCustomers() {

            const {data} = await axiosInstance.get(CUSTOMER_API)
            setCustomers(data)
        }
        fetchAndSetCustomers()

    }, [axiosInstance,setIsLoading])

    function handleClose() {
        setShowModal(false);
        setShowViewSection(false);
    }

  
    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton/>
                    <h1 className="heading">{`Customers (${customers?.length > 0 ? customers.length : 0} in total)`}</h1>
                    </div>
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th= 'Customer Name' />
                        <CRUDth th= 'Email' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {customers?.map(customer => (
                            <CRUDTableRow key={customer._id}>
                                <ShortTextCell text={customer.name} />
                                
                                <ShortTextCell text={customer.email} />
                                <td className="action_button_cell">
                                    <ViewButton
                                    setShowModal={setShowModal}
                                    setShowViewSection={setShowViewSection}
                                    targetID={customer._id}
                                    setTargetID={setTargetID}
                                    />
                                    {/* <CRUDButton
                                    dataTip='Address'
                                    dataFor='Address Item'
                                    handleClick={() =>
                                    navigate("/address/" + customer._id)
                                    }>
                                        <RiMapPinLine />

                                    </CRUDButton>

                                    <CRUDButton
                                    dataTip='Order'
                                    dataFor='Customer Order Item'
                                    handleClick={() =>
                                    navigate("/customer/orderScreen/" + customer._id)
                                    }>
                                        <RiShoppingCartLine />


                                    </CRUDButton> */}
                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal && 
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Customer`)
                    }
                >
                    {
                        showViewSection &&
                            <ViewCustomer
                                targetID={targetID}
                                
                            />
                    }    
                </Modal>
            }
        </>
    )
}

export default Customers