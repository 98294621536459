import { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SwitchInput from "../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";


function CreateSocialMediaScreen({

  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) {
  const [name, setName] = useState(null);
  const [link, setLink] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();


    itemData.append("name", name);
    itemData.append("visibility", visibility);
    itemData.append("link", link);

    const response = await axiosInstance.post("websiteContents/socialLinks",itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <SwitchInput
        label={"visibility"}
        toggleSwitch={() => setVisibility((prevState) => !prevState)}
        checked={visibility}
      />
      <ShortTextInput
        label={`Link`}
        value={link}
        placeholder={`Enter Link`}
        setState={setLink}
      />

      <FormSubmitButton text="Create Social Link" />
    </Form>
  );
}

export default CreateSocialMediaScreen;
