function formatTimeIn12Hour(date) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    
    // Pad minutes with leading zero if necessary
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    
    // Combine hours, minutes, and AM/PM to get the formatted time
    const formattedTime = hours + ':' + minutesStr + ' ' + ampm;
    
    return formattedTime;
  }

  export default formatTimeIn12Hour