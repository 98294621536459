import { RiAdvertisementLine, RiDirectionLine, RiGroup2Line, RiGroupLine } from "react-icons/ri"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"



function AppSettingsScreens() {
    return (

        <div>
        
            <NavCardList numOfCards={'four'}>
                <NavCard cardName={'Hero Sliders'} navCardLink='/heroSliders' ><RiGroup2Line /></NavCard>
                <NavCard cardName={'Social Links'} navCardLink='/socialLinks' ><RiGroupLine /></NavCard>
                <NavCard cardName={'Ads'} navCardLink='/ads' ><RiAdvertisementLine /></NavCard>
                <NavCard cardName={'Locations'} navCardLink='/countries' ><RiDirectionLine /></NavCard>
                {/* <NavCard cardName={'Promo Codes'} navCardLink='/promocodes' ><RiAdvertisementLine /></NavCard> */}
            </NavCardList>
        </div>


    )
}

export default AppSettingsScreens