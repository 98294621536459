

import "./Tabs.css";

function Tabs({ activeTab, hall }) {

  return (
    <div className="tabs">
      <div className={`tab ${activeTab >= 1 ? "active-tab" : ""}`}>
        <p className="tab-no">1</p>
        <p className="tab-title">Hall Info</p>
      </div>
      <div className={`tab ${activeTab >= 2 ? "active-tab" : ""}`}>
        <p className="tab-no">2</p>
        <p className="tab-title">Seating View</p>
      </div>

      {hall && (
        <div className={`tab ${activeTab === 3 ? "active-tab" : ""}`}>
          <p className="tab-no">3</p>
          <p className="tab-title">Naming View </p>
        </div>
      )}
    </div>
  );
}

export default Tabs;
