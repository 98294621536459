import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function UpdatePromocode({ targetID,
    setShowUpdateForm,
    setShowModal,
    triggerFetch, }) {

    const axiosInstance = useAxiosInstance();
    const [promoCode, setPromoCode] = useState("");
    const [maxlimit, setMaxLimit] = useState(0);
    const [discountType, setDiscountType] = useState("percentage");
    const [discountAmount, setDiscountAmount] = useState(0);

    useEffect(() => {
        async function fetchAndSetAds() {
            const { data } = await axiosInstance.get("promoCode/getSinglePromoCodes/" + targetID);
            if (data) {
                setPromoCode(data?.promoCode)
                setMaxLimit(data?.maxlimit)
                setDiscountType(data?.discountType)
                setDiscountAmount(data?.discountAmount)
            }

        }
        fetchAndSetAds();
    }, [targetID, axiosInstance]);

    async function handleSubmit(e) {
        e.preventDefault();

        const itemData = new FormData();
        itemData.append("promoCode", promoCode);
        itemData.append("maxlimit", maxlimit);
        itemData.append("discountType", discountType);
        itemData.append("discountAmount", discountAmount);

        try {
            const response = await axiosInstance.patch("promoCode/" + targetID, itemData);
            if (response.data) {
                setShowUpdateForm(false);
                setShowModal(false);
                triggerFetch();
            }
        } catch (error) {
            console.error("Error creating promo code:", error);
        }
    }

    return (
        
        <Form onSubmit={handleSubmit}>

            <SelectInput label="Discount Type" value={discountType} setState={setDiscountType}>
                <SelectOption optionValue="percentage" optionText="Percentage" />
                <SelectOption optionValue="amount" optionText="Amount" />
                <SelectOption optionValue="free_ticket" optionText="Free Ticket" />
            </SelectInput>

            <ShortTextInput
                label="Discount Amount"
                type="number"
                value={discountAmount}
                placeholder="Enter Discount Amount"
                setState={setDiscountAmount}
                required
            />


            <NumberInput
                label="Max Limit"
                value={maxlimit}
                placeholder="Enter Max Limit"
                setState={setMaxLimit}
                required
            />


            <FormSubmitButton text="Create Promo Code" />
        </Form>
    )
}

export default UpdatePromocode
