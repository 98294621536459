import React from 'react';
import SeatIcon from './SeatIcon';
import DropdownMenu from './DropdownMenu';

function SeatButton({ seat, handleSeatClick, selectedSeatId, view, handleSeatTypeChange, setSeats, setColumnTypes, setRowTypes, }) {
    return (
        <button
            type="button"
            className={`square-button ${seat?.status}`}
            onClick={() => view ? "" : handleSeatClick(seat?.seatId)}
        >
            <div className="single-checkbox-button">
                <SeatIcon status={seat?.status} />
            </div>

            {selectedSeatId === seat?.seatId && (
                <DropdownMenu
                    type="seat"
                    index={seat?.seatId}
                    handleSelect={(index, newType) => handleSeatTypeChange(index, newType, setSeats, setColumnTypes, setRowTypes)}
                />
            )}
            
        </button>
    );
}

export default SeatButton;
