import { useState, useEffect, useContext } from 'react';
import { CSVLink } from "react-csv";
// import { useSelector } from "react-redux"

import AppContext from '../../../Context/AppContext';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import BackButton from '../../Partials/Elements/BackButton/BackButton';
import FilterSelect from '../../Partials/Layouts/Forms/FilterSelect/FilterSelect';
import CreateButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import ViewButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import ArchiveButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton';
import Modal from '../../Partials/Elements/Modal/Modal';

import { useNavigate, useParams } from 'react-router-dom';
import CRUDButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton';
import { FiImage } from 'react-icons/fi';
import { ORDERS_API } from '../../../Utilities/APIs/APIs';
import ViewOrder from './ViewOrder';
import { RiDeleteBin2Line, RiInboxArchiveLine } from 'react-icons/ri';
import OrderEmail from './orderEmail/orderEmail';
import { MdOutlineEmail } from 'react-icons/md';
import ArchiveOrder from './archiveOrder/archiveOrder';
import ViewSelect from '../../Partials/Layouts/Forms/FilterSelect/ViewSelect';
import formatTimeIn12Hour from '../../../Utilities/HelperFunction/timeFunctions';
import SeatNameCsv from './SeatNameCsv/SeatNameCsv';

function Orders() {

  const { showId } = useParams()
  const [orders, setOrders] = useState(null);
  const [ordersBySeatName, setOrdersBySeatName] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showSeatNameDownloadSection, setShowSeatNameDownloadSection] = useState(false)

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState('active');
  const [view, setView] = useState('details');
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetOrders() {
      const { data } = await axiosInstance.get(`/orders?filter=${filter}&showId=${showId}`);
      setOrders(data);
    }
    fetchAndSetOrders();
  }, [toggleFetch, filter, showId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
    setShowSeatNameDownloadSection(false)
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  const processedOrdersForCSV = orders?.map((order) => ({
    ...order,
    showTime: `${new Date(order?.show?.date).toDateString()} - ${order?.show?.startTime}`
  }))


  const headers = [
    { label: "Order ID", key: "orderId" },
    { label: "Customer", key: "name" },
    { label: "Theater", key: "show.theater.name" },
    { label: "Hall", key: "show.hall.name" },
    { label: "Event", key: "event.name" },
    { label: "Total", key: "total" },
    { label: "Transaction Id", key: "transactionId" },
    { label: "Moment", key: "createdAt" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobileNumber" },
    { label: "Seats", key: "seats" },
    { label: "Show Time", key: "showTime" }
  ];


  const csvReport = {
    filename: 'OrderDetails.csv',
    headers: headers,
    data: processedOrdersForCSV
  };


  const theaterAndHall = orders && `${orders[0]?.show?.theater?.name ?? ''}--${orders[0]?.show?.hall?.name ?? ''}--${new Date(orders[0]?.show?.date).toLocaleDateString() ?? ''}`;



  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>

          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />

            <h1 className='heading'>{`${filter === 'active' ? `Orders - ${theaterAndHall}` : `Archived Orders - ${theaterAndHall}`
              } (${orders?.length} in total)`}</h1>
          </div>

          <div className='order_header'>
            <FilterSelect filter={filter} setFilter={setFilter} />
            <ViewSelect filter={view} setFilter={setView} />

            {orders?.length > 0 && <CSVLink {...csvReport}>Order CSV</CSVLink>}

            <button onClick={() => setShowSeatNameDownloadSection(true)} style={{color:"var(--primary-color)"}}>SeatName CSV</button>
          </div>

          {/* <CreateButton
            screenTopicSingular="Order"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          /> */}

        </div>
        <CRUDTable>
          <CRUDTableHeader>
            {
              view === 'details' && (
                <>
                  <CRUDth th='Event' />
                  <CRUDth th='Customer' />
                  {/* <CRUDth th='Show' /> */}
                  <CRUDth th='Order Id' />
                </>
              )
            }
            {
              view === 'stripe' && (
                <>
                  {/* <CRUDth th='Order Id' /> */}
                  <CRUDth th='Moment' />
                  <CRUDth th='Email' />
                  <CRUDth th='Transaction Id' />
                </>
              )
            }
            <CRUDth th='Revenue' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {orders?.map((order) => (
              <CRUDTableRow key={order._id}>

                {
                  view === 'details' && (
                    <>
                      <ShortTextCell
                        text={
                          order?.event?.name?.length > 20
                            ? `${order.event.name.slice(0, 20)}...`
                            : order?.event?.name
                        }
                      />
                      <ShortTextCell text={order?.name} />

                      {/* <ShortTextCell
                        text={`${order?.show?.theater?.name}--${
                          order?.show?.hall?.name
                        }--${new Date(order?.show?.date).toLocaleDateString()}`}
                      /> */}
                      <ShortTextCell text={order?.orderId} />
                      
                    </>
                  )
                }
                {
                  view === 'stripe' && (
                    <>
                      <ShortTextCell text={new Date(order?.createdAt).toDateString() + ", " + formatTimeIn12Hour(new Date(order?.createdAt))} />
                      {/* <ShortTextCell text={order?.orderId} /> */}
                      <ShortTextCell text={order?.email} />
                      <ShortTextCell text={order?.transactionId} />

                    </>
                  )
                }

                <ShortTextCell text={order?.total} />

                <td className='action_button_cell'>
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={order._id}
                    setTargetID={setTargetID}
                  />
                  {/* <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={order._id}
                    isArchive={order?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  /> */}
                  <CRUDButton
                    dataTip='Send Email'
                    dataFor='Send Email'
                    handleClick={() => {
                      setShowModal(true);
                      setShowSendEmail(true);
                      setTargetID(order._id);
                    }}
                  >
                    <MdOutlineEmail />
                  </CRUDButton>
                  <CRUDButton
                    dataTip=' Order Items'
                    dataFor=' Order Items'
                    handleClick={() => navigate('/orderItems/' + order._id)}
                  >
                    <FiImage />
                  </CRUDButton>
                  {order.isActive && (
                    <CRUDButton
                      dataTip={order?.isActive ? "Archive" : "Unarchive"}
                      dataFor='Archive Order'
                      handleClick={() => {
                        setShowModal(true);
                        setShowDeleteSection(true);
                        setTargetID(order._id);
                      }}
                    >
                      <RiInboxArchiveLine />
                    </CRUDButton>
                  )}
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {/* {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showUpdateForm && `Update Order`) ||
            (showDeleteSection && `Archive Order`)
          }
        >

          {showDeleteSection && (
            <ArchiveItem
              api={"theaters/archiveTheater/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )} */}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Order`}
        >
          <ViewOrder targetID={targetID} />
        </Modal>
      )}
      {showModal && showDeleteSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showDeleteSection && `Cancel Order`}
        >
          <ArchiveOrder
            setShowDeleteSection={setShowDeleteSection}
            setShowModal={setShowModal}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showModal && showSendEmail && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showSendEmail && `Send Email`}
        >
          <OrderEmail
            setShowDeleteSection={setShowSendEmail}
            setShowModal={setShowModal}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showSeatNameDownloadSection && (
        <Modal
       
          handleClose={handleClose}
          modalHeading={showSeatNameDownloadSection && `Seatname Csv`}
        >
          <SeatNameCsv
            showId={showId}
            setShowSeatNameDownloadSection={setShowSeatNameDownloadSection}
          />
        </Modal>
      )}
    </>
  );
}

export default Orders;
