import { useEffect, useState } from 'react';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import { ORDERS_API } from '../../../Utilities/APIs/APIs';

function ViewOrder({ targetID }) {
  const [order, setOrder] = useState(null);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetOrder() {
      const { data } = await axiosInstance.get(
        ORDERS_API + 'getSingleOrder/' + targetID,
      );
      setOrder(data);
    }
    fetchAndSetOrder();
  }, [targetID, axiosInstance]);

  console.log('order', order);
  return (
    <div className='crud_view_content'>
      {order && (
        <>
          <h1>Transaction</h1>
          <p>{order.transactionId}</p>

          <h1>Show</h1>
          <p>{`${order?.show?.theater?.name}--${
            order?.show?.hall?.name
          }--${new Date(order?.show?.date).toLocaleDateString()}`}</p>

          <h1>Event Name</h1>
          <p>{order?.event.name}</p>

          <h1>Customer Name</h1>
          <p>{order?.name}</p>

          <h1>Email</h1>
          <p>{order?.email}</p>

          <h1>Phone</h1>
          <p>{order?.mobileNumber}</p>

          <h1>Discount</h1>
          <p>{order?.discount}</p>

          <h1>Payment Method</h1>
          <p>{order?.paymentMethod}</p>

          <h1>Total</h1>
          <p>{order?.total}</p>
          <h1>Payment Method</h1>
          <p>{order?.paymentMethod}</p>
          <h1>OrderId</h1>
          <p>{order?.orderId}</p>
          <h1>Date</h1>
          <p>{new Date(order?.createdAt).toDateString()}</p>
        </>
      )}
    </div>
  );
}

export default ViewOrder;
