export const IMAGE_URL = process.env.REACT_APP_SPACES_URL 




// employee
export const ALL_EMPLOYESS_API= "employees/"

// Invite employess
// export const ALL_EMPLOYEE_INVITES_API = 'employeeInvite/'

export const EMPLOYEE_INVITES_API ='employeeInvite/'
// customers
export const CUSTOMER_API = "customers/"

export const ORDERS_API = "orders/"
