
import { RiInboxArchiveLine } from 'react-icons/ri';

import './ArchiveButton.css';
import CRUDButton from '../CRUDButton';
import { archiveButtonDataTip } from '../../../../../../../../Utilities/HelperFunction/archiveButtonDataTip';


function ArchiveButton({ setShowModal, setShowDeleteSection, targetID, isActive, isArchive, setTargetID, setActive, setArchive }) {

  const dataTip = archiveButtonDataTip(isActive, isArchive)

  return (
    <CRUDButton
      dataTip={dataTip}
      dataFor='Archive Item'
      handleClick={() => {
        setShowModal(true)
        setShowDeleteSection(true)
        setTargetID(targetID)
        if (isActive !== undefined) {
          setActive(isActive);
        } else {
          setArchive(isArchive);
        }
      }}
      deleteButton
    >
      <RiInboxArchiveLine />

    </CRUDButton>

  )
}

export default ArchiveButton