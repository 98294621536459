import "./SingleSeatModal.css";

function SingleSeatModal({ isOpen, onClose, onSave, seatName, setSeatName }) {
  if (!isOpen) return null;

  return (
    <div className="naming_modal_overlay">
      <div className="namingModal-content">
        
        <h3>Enter Seat Name</h3>
        <input
        className="naming_input_field"
          type="text"
          value={seatName}
          onChange={(e) => setSeatName(e.target.value)}
        />
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"20px"}}>
        <button className="naming-button close" onClick={onClose}>Close</button>
        <button className="naming-button save" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default SingleSeatModal;
