import { BiListCheck } from "react-icons/bi";
import { IoTicketSharp } from "react-icons/io5";
import OrderCard from "../OrderCard/OrderCard";


function OrderDetailsWrapper({ totalOrder, totalSoldTickets, yesterdaySoldTickets, todaySoldTickets, last24hrSoldTickets }) {
  return (
    <div className="order_details_container">
      <OrderCard icon={<BiListCheck />} heading={"Total Orders"} total={totalOrder} />
      <OrderCard icon={<IoTicketSharp />} heading={"Total Tickets Sold"} total={totalSoldTickets} />
      <OrderCard icon={<IoTicketSharp />} heading={"Yesterday Tickets Sold"} total={yesterdaySoldTickets} />
      <OrderCard icon={<IoTicketSharp />} heading={"Today Tickets Sold"} total={todaySoldTickets} />
      <OrderCard icon={<IoTicketSharp />} heading={"Last 24 Hours Tickets Sold"} total={last24hrSoldTickets} />
    </div>
  )
}

export default OrderDetailsWrapper