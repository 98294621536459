import React from 'react';
import SeatIcon from './SeatIcon';

function DropdownMenu({ type, index, handleSelect, col }) {
    const seatTypes = ["recliner", "premium", "standard", "kids", "wheelchair", "stair", "unavailable"];

    return (
        <div className={`dropdown-menu ${type}`}>
            {seatTypes.map(seatType => (

                <button
                    key={seatType}
                    type="button"
                    className={`dropdown-button ${col && 'col'}`}
                    onClick={() => handleSelect(index, seatType)}
                >

                    <span className={`status-icon ${seatType}`}>
                        <SeatIcon status={seatType} />
                    </span>

                </button>
            ))}
        </div>
    );
}

export default DropdownMenu;
