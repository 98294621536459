import CardLlinkButton from "./CardLinkButton/CardLlinkButton"
import { FiEdit, FiEye } from "react-icons/fi"


function CardLink({ eventId }) {
    return (
        <div className="event_btn_container">
            <CardLlinkButton icon={<FiEdit />} buttonText={"Event"} link={`/eventsScreens/${eventId}`} />
            <CardLlinkButton icon={<FiEye />} buttonText={"Shows"} link={`/events/shows/${eventId}`} />
        </div>
    )
}

export default CardLink