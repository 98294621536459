
import { RiMoneyCnyBoxLine} from "react-icons/ri"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"


function FeesScreen() {
    return (
        <div>
            <NavCardList numOfCards={'four'}>
                <NavCard cardName={'Promo codes'} navCardLink='/promocodes' ><RiMoneyCnyBoxLine/></NavCard>
            </NavCardList>
        </div>

    )
}

export default FeesScreen