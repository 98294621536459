import { Link } from "react-router-dom"


function SeeMoreButton() {
  return (
    <div className="see_btn_container">
        <Link to={"/events/nowSelling"}>
        <button >See All</button>
        </Link>
    </div>
  )
}

export default SeeMoreButton