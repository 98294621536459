import React, { useEffect, useState } from "react";
import {
    generateSeats,
    handleSeatTypeChange,
    handleRowSelect,
    handleColumnSelect,
    handleButtonStatusClick
} from "../../../../../Utilities/HelperFunction/seatFunction";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import "./SeatManagement.css";

function SeatManagement({ rows, columns, setSeats, seats, template, view }) {

    const [selectedSeatId, setSelectedSeatId] = useState(null);
    const [activeDropdown, setActiveDropdown] = useState({ type: null, index: null });
    const [rowTypes, setRowTypes] = useState({});
    const [columnTypes, setColumnTypes] = useState({});

    
    useEffect(() => {
        if (template || view || seats.length > 0) return;

        if (rows > 0 && columns > 0) {
            const newSeats = generateSeats(rows, columns);
            setSeats(newSeats);
        }
    }, [rows, columns, setSeats, template, seats]);
    

    const handleSeatClickWrapper = (seatId) => {
        setSelectedSeatId(seatId === selectedSeatId ? null : seatId);
    };


    return (
        <div className="seat-management">
            {seats?.length > 0 && (
                <table>
                    
                    <TableHeader
                        view={view}
                        columns={columns}
                        columnTypes={columnTypes}
                        activeDropdown={activeDropdown}
                        setColumnTypes={setColumnTypes}
                        setActiveDropdown={setActiveDropdown}
                        setSeats={setSeats}
                        handleColumnSelect={(colIndex, newType) => handleColumnSelect(colIndex, newType, setSeats, setColumnTypes, setActiveDropdown)}
                        handleButtonStatusClick={(type, index, activeDropdown, columnTypes) => handleButtonStatusClick(type, index, activeDropdown, setActiveDropdown, rowTypes, columnTypes, setRowTypes, setColumnTypes, setSeats)}
                    />

                    <TableBody
                        rows={rows}
                        columns={columns}
                        setSeats={setSeats}
                        setRowTypes={setRowTypes}
                        setColumnTypes={setColumnTypes}
                        seats={seats}
                        rowTypes={rowTypes}
                        setActiveDropdown={setActiveDropdown}
                        activeDropdown={activeDropdown}
                        handleRowSelect={(rowIndex, newType) => handleRowSelect(rowIndex, newType, setSeats, setRowTypes, setActiveDropdown)}
                        handleButtonStatusClick={(type, index, activeDropdown, rowTypes) => handleButtonStatusClick(type, index, activeDropdown, setActiveDropdown, rowTypes, columnTypes, setRowTypes, setColumnTypes, setSeats)}
                        handleSeatClick={handleSeatClickWrapper}
                        handleSeatTypeChange={handleSeatTypeChange}
                        selectedSeatId={selectedSeatId}
                        view={view}
                    />
                </table>
            )}
        </div>
    );
}

export default SeatManagement;
