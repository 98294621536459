
import { useEffect, useState } from "react";
import "./Dashboard.css";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import EventDetails from "./EventDetails/EventDetails";
import OrderAmountDetails from "./OrderAmountDetails/OrderAmountDetails";
import DashboardWrappper from "./DashboardWrappper";

function Dashboard() {
  const axiosInstance = useAxiosInstance()

  const [nowSellingEvents, setNowSellingEvents] = useState([])
  // const [totalAmount, setTotalAmount] = useState(0)
  const [totalOrder, setTotalOrder] = useState(0)
  const [totalSoldTickets, setTotalSoldTickets] = useState(0)
  const [yesterdaySoldTickets, setYesterdaySoldTickets] = useState(0)
  const [todaySoldTickets, setTodaySoldTickets] = useState(0)
  const [last24hrSoldTickets, setLast24SoldTickets] = useState(0)

  useEffect(() => {
    async function setAndFetchEventsInfo() {
      const { data } = await axiosInstance.get("/getDashboardInfo")

      setNowSellingEvents(data.nowSellingEvents)
      // setTotalAmount(data.totalAmount)
      setTotalOrder(data.totalOrder)
      setTotalSoldTickets(data.soldTickts)
      setYesterdaySoldTickets(data.yesterdaySoldTickets)
      setTodaySoldTickets(data.todaySoldTickets)
      setLast24SoldTickets(data.last24hourTickets)
    }
    setAndFetchEventsInfo()
  }, [axiosInstance])

  return (

    <DashboardWrappper >
      <EventDetails nowSellingEvents={nowSellingEvents} />
      <OrderAmountDetails
        totalOrder={totalOrder}
        totalSoldTickets={totalSoldTickets}
        yesterdaySoldTickets={yesterdaySoldTickets}
        todaySoldTickets={todaySoldTickets}
        last24hrSoldTickets={last24hrSoldTickets}
      />
    </DashboardWrappper>

  );
}

export default Dashboard;
