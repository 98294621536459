import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateCrew({
  eventId,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
  targetID,
}) {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCrew() {
      const { data } = await axiosInstance.get(
        "events/crews/getSingleCrew/" + targetID
      );
      setName(data.name);
      setType(data.type);
    }
    fetchAndSetCrew();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("type", type);

    const response = await axiosInstance.patch(
      "events/crews/" + targetID,
      itemData
    );
    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Type"} value={type} setState={setType}>
        <SelectOption optionValue="" optionText="Select Type" />
        <SelectOption optionValue="Director" optionText="Director" />
        <SelectOption optionValue="Producer" optionText="Producer" />
        <SelectOption optionValue="Cast" optionText="Cast" />
        <SelectOption
          optionValue="localDistributor"
          optionText="Local Distributor"
        />
      </SelectInput>

      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Event Name`}
        setState={setName}
      />

      <FormSubmitButton text="Update Crew" />
    </Form>
  );
}

export default UpdateCrew;
