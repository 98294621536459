import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LuView } from "react-icons/lu";
import { PiArmchairLight } from "react-icons/pi";


import AppContext from "../../../../Context/AppContext";

import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateShows from "./CreateShows";
import UpdateShows from "./UpdateShows";
import HallModal from "../../../Partials/Elements/HallModal/HallModal";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { RiEdit2Line, RiImage2Line, RiOrderPlayLine } from "react-icons/ri";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import ViewShows from "./ViewShows";
import ViewShowSeat from "./ViewShowSeat/ViewShowSeat";
import UpdateShowseat from "./UpdateShowSeat/UpdateShowseat";

function Shows() {
  const { eventId } = useParams();

  const [shows, setShows] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [showSeatSection, setShowSeatSection] = useState(false);
  const [showSeatUpdateSection, setShowUpdateSeatStatus] = useState(false);

  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);


  const navigate = useNavigate()

  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetShows() {
      const { data } = await axiosInstance.get(
        `events/shows/getAllShowsByEventId/${eventId}?filter=${filter}`
      );
      setShows(data);
    }
    fetchAndSetShows();
  }, [toggleFetch, axiosInstance, eventId, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function calculateTicktetsSold(show) {
    // const totalSeats = show?.totalSeats
    // const totalSoldTickets = show?.totalSoldTickets

    const totalSeats = 115
    const totalSoldTickets = 10

    const salePercentage = totalSeats > 0 ? (totalSoldTickets / totalSeats) * 100 : 0;

    return salePercentage;
  }

  console.log(calculateTicktetsSold())

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Shows - ${shows && shows[0]?.event.name}  (${shows?.length} in total)`}</h1>
          </div>

          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Shows"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Tickets" />
            <CRUDth th="Status" />
            <CRUDth th="Theater" />
            <CRUDth th="Hall" />
            {/* <CRUDth th="Country" />
            <CRUDth th="State" /> */}
            {/* <CRUDth th="City" /> */}
            <CRUDth th="Start Time" />
            <CRUDth th="Date" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {shows?.length > 0 &&
              shows?.map((show) => (

                <CRUDTableRow key={show._id}>
                  {
                    (show?.totalSeats && show?.totalSoldTickets) ?
                      <ShortTextCell
                        text={`${show?.totalSoldTickets} out of ${show?.totalSeats
                          } (${parseInt((show?.totalSoldTickets / show?.totalSeats) * 100)
                          }%) sold`}
                      /> : <ShortTextCell text={" Information Not Available "} />
                  }

                  <td

                  >
                    <span className={
                      ((show?.totalSeats - show?.totalSoldTickets) / show?.totalSeats) * 100 < 30
                        ? ((show?.totalSeats - show?.totalSoldTickets) / show?.totalSeats) * 100 === 0
                          ? 'soldOut'
                          : 'sellingFast'
                        : 'sellingSlow'
                    }>
                      {
                        ((show?.totalSeats - show?.totalSoldTickets) / show?.totalSeats) * 100 < 30
                          ? ((show?.totalSeats - show?.totalSoldTickets) / show?.totalSeats) * 100 === 0
                            ? "Sold Out"
                            : "Selling Fast"
                          : "Selling Slow"
                      }
                    </span>

                  </td>


                  <ShortTextCell text={show?.theater?.name} />
                  <ShortTextCell text={show?.hall?.name} />
                  {/* <ShortTextCell text={show?.country?.name} />
                  <ShortTextCell text={show?.state?.name} /> */}
                  {/* <ShortTextCell text={show?.city?.name} /> */}
                  <ShortTextCell text={show.startTime} />
                  <ShortTextCell text={new Date(show?.date).toLocaleDateString()} />
 
                  <td className="action_button_cell">
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={show._id}
                      setTargetID={setTargetID}
                    />
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={show._id}
                      setTargetID={setTargetID}
                    />

                    <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={show._id}
                      isArchive={show?.isArchive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    />

                    <CRUDButton
                      dataTip={"Shows Seat"}
                      dataFor={"Shows Seat"}
                      handleClick={() => {
                        setTargetID(show._id)
                        setShowModal(true)
                        setShowUpdateSeatStatus(true)
                      }}>
                      <PiArmchairLight />
                    </CRUDButton>
                    {/* 
                    <CRUDButton
                      dataTip={"Shows Seat"}
                      dataFor={"Shows Seat"}
                      handleClick={() => {
                        setTargetID(show._id)
                        setShowModal(true)
                        setShowSeatSection(true)
                      }}>
                       <LuView />
                    </CRUDButton> */}


                    <CRUDButton
                      dataTip={"Shows Price"}
                      dataFor={"Shows Price"}
                      handleClick={() => {
                        navigate(`/shows/prices/${show._id}`)
                      }}>
                      <RiImage2Line />
                    </CRUDButton>

                    <CRUDButton
                      dataTip={"Orders"}
                      dataFor={"Orders"}
                      handleClick={() => {
                        navigate(`/shows/orders/${show._id}`)
                      }}>
                      <RiOrderPlayLine />
                    </CRUDButton>


                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && showUpdateForm && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showUpdateForm && `Update Shows`}
        >
          {showUpdateForm && (
            <UpdateShows
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              eventId={eventId}
            />
          )}
        </Modal>
      )}

      {showModal && showDeleteSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showDeleteSection && `Archive Shows`}
        >
          <ArchiveItem
            api={"events/shows/archiveShows/"}
            targetID={targetID}
            setShowModal={setShowModal}
            setShowDeleteSection={setShowDeleteSection}
            triggerFetch={triggerFetch}
            isArchive={archive}
          />
        </Modal>
      )}

      {showModal && showCreateForm && (
        <HallModal setShowModalContent={setShowCreateForm}>
          <CreateShows
            employee={employee}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
            eventId={eventId}
          />
        </HallModal>
      )}


      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Shows`}
        >
          <ViewShows
            targetID={targetID}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {/* {showModal && showSeatSection && (

        <HallModal
          setShowModalContent={setShowSeatSection}
        >
          <ViewShowSeat
            targetID={targetID}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </HallModal>
      )} */}

      {showModal && showSeatUpdateSection && (

        <HallModal
          setShowModalContent={setShowUpdateSeatStatus}
        >
          <UpdateShowseat
            targetID={targetID}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
            toggleFetch={toggleFetch}
          />
        </HallModal>
      )}


    </>
  );
}

export default Shows;
