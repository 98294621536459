


import { useEffect, useState } from "react";


import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewShows({targetID}) {
  const [shows, setShows] = useState(null);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndsetShows() {
      const { data } = await axiosInstance.get("events/shows/getSingleShow/" + targetID);
      setShows(data.show)
    }
    fetchAndsetShows();
  }, [targetID, axiosInstance]);

  return (
    
    <div className="crud_view_content">
      {shows && (
         <>
         <h1>Country Name</h1>
         <p>{shows?.country?.name}</p>

         <h1>State Name</h1>
         <p>{shows?.state?.name}</p>

         <h1>City Name</h1>
         <p>{shows?.city?.name}</p>

         <h1>Theater Name</h1>
         <p>{shows?.theater?.name}</p>

         <h1>Hall Name</h1>
         <p>{shows?.hall?.name}</p>

       </>
      )}
    </div>
  );
}

export default ViewShows;
