import { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";




function CreateCountry({ setShowCreateForm, setShowModal, triggerFetch,countryId}) {

  const [name, setName] = useState("");

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("name", name);

    const response = await axiosInstance.post("countries", itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Country Name`}
        value={name}
        placeholder={`Enter Country Name`}
        setState={setName}
      />
      <FormSubmitButton text="Create Country" />
    </Form>
  );
}

export default CreateCountry;
