import Tabs from "../../../Theaters/Entrances/Tabs/Tabs"

function CreateShowsButton({activeTab,handlePrev,handleNext,handleSubmit,startTime,date,hall,city,state,theater}) {
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    }}
    >
    <Tabs activeTab={activeTab} />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <button
      type="button"
      onClick={handlePrev}
        style={{
          border: "1px solid lightgray",
          color: "white",
          textAlign: "center",
          width: "200px",
          padding: "10px",
          background: "var(--gray-8)",
          borderRadius: "10px",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        Back
      </button>
    
      <button
        style={{
          textAlign: "center",
          width: "200px",
          padding: "10px",
          background: "var(--primary-color)",
          borderRadius: "10px",
          fontSize: "18px",
          fontWeight: "600",
        }}
        type="button"
        onClick={activeTab < 2 ? handleNext : handleSubmit}
        disabled={!startTime || !date || !hall || !city || !state || !theater
        }
      >
        {activeTab === 1 ? " Next " : "Confirm"}
      </button>
     
    </div>
    </div>
  )
}


export default CreateShowsButton


