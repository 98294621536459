

function DashboardWrappper({ children }) {
  return (
    <div className="dashboard_crud_holder">
      <div className="dashboard">
        {children}
      </div>
    </div>
  )
}

export default DashboardWrappper