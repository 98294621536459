import React from 'react';
import { FaWheelchair, FaBan, FaChair, FaCrown, FaChild } from 'react-icons/fa';
import { PiStairsThin } from 'react-icons/pi';

function SeatIcon({ status }) {
  switch (status) {
    case "recliner":
      return <FaChair color="darkviolet" size={14} />;
    case "premium":
      return <FaCrown color="goldenrod" size={14} />;
    case "standard":
      return <FaChair color="coral" size={14} />;
    case "kids":
      return <FaChild color="skyblue" size={14} />;
    case "wheelchair":
      return <FaWheelchair color="darkorange" size={14} />;
    case "stair":
      return <PiStairsThin color="darkgray" size={14} />;
    case "unavailable":
      return <FaBan color="crimson" size={14} />;
    default:
      return null;
  }
}

export default SeatIcon;
