import React, { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";


function CreatePromocode({ setShowCreateForm, setShowModal, triggerFetch }) {
  const axiosInstance = useAxiosInstance();
  const [promoCode, setPromoCode] = useState("");
  const [maxlimit, setMaxlimit] = useState(0);
  const [discountType, setDiscountType] = useState("percentage");
  const [discountAmount, setDiscountAmount] = useState(0);

  async function handleSubmit(e) {
    e.preventDefault();
    const promocodeData = {
      promoCode,
      maxlimit,
      discountType,
      discountAmount,
    };

    try {
      const response = await axiosInstance.post("promoCode", promocodeData);
      if (response.data) {
        setShowCreateForm(false);
        setShowModal(false);
        triggerFetch();
      }
    } catch (error) {
      console.error("Error creating promo code:", error);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label="Promo Code"
        value={promoCode}
        placeholder="Enter Promo Code"
        setState={setPromoCode}
        required
      />
      <ShortTextInput
        label="Max Limit"
        type="number"
        value={maxlimit}
        placeholder="Enter Max Limit"
        setState={setMaxlimit}
        required
      />
      <SelectInput label="Discount Type" value={discountType} setState={setDiscountType}>
        <SelectOption optionValue="percentage" optionText="Percentage" />
        <SelectOption optionValue="amount" optionText="Amount" />
        <SelectOption optionValue="free_ticket" optionText="Free Ticket" />
      </SelectInput>

      <ShortTextInput
        label="Discount Amount"
        type="number"
        value={discountAmount}
        placeholder="Enter Discount Amount"
        setState={setDiscountAmount}
        required
      />

      <FormSubmitButton text="Create Promo Code" />
    </Form>
  );
}

export default CreatePromocode;