import "./SearchMethod.css"

function SearchMethod({ searchMethod, setSearchMethod, setSearchResult,setSearchText }) {
    return (
        <>
            <label className="input_field_label caption bold">Select Search Method</label>
            <select
                className='search_select'
                value={searchMethod}
                onChange={e => {
                    setSearchMethod(e.target.value)
                    setSearchResult("")
                    setSearchText("")
                }}
            >
                <option value={"orderId"}>Search By Order Id</option>
                <option value={"transaction"}>Search By Transaction Id</option>
            </select>
        </>
    )
}

export default SearchMethod