export function handleGetTooltipContent(seat) {

    if (seat?.status === 'stair' || seat?.status === 'unavailable') {
        return seat.status.charAt(0).toUpperCase() + seat.status.slice(1);
    } else if (seat?.isBooked) {
        return `${seat?.seatName} - (Booked)`;
    } else if (!seat?.isBooked && seat?.isTemporaryBooked) {
        return `${seat?.seatName} - (Temporary Booked)`;
    } else {
        return seat?.seatName
            ? `${seat?.seatName} - (${seat?.status})`
            : 'Unavailable';
    }

};