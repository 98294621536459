import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateState({
    targetID,
    employee,
    setShowUpdateForm,
    setShowModal,
    triggerFetch,
}) {
    const [name, setName] = useState("");
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetCityName() {
            const { data } = await axiosInstance.get("states/cities/getSingleCity/" + targetID);
            setName(data.name);

        }
        fetchAndSetCityName();
    }, [targetID, axiosInstance]);

    async function handleSubmit(e) {
        e.preventDefault();

        const itemData = new FormData();
        itemData.append("name", name);

        const response = await axiosInstance.patch("states/cities/" + targetID, itemData);

        if (response.data) {
            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`City Name`}
                value={name}
                placeholder={`Enter City Name`}
                setState={setName}
            />

            <FormSubmitButton text="Update City" />
        </Form>
    );
}

export default UpdateState;
