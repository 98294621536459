
// import './NamingSeatManagement.css';

// function NamingModal  ({ rows,isOpen, onClose, onSelectLetter }) {

//     function generateLetters(count) {
//         const letters = [];
//         for (let i = 0; i < count; i++) {
//           let label = '';
//           let num = i;
//           while (num >= 0) {
//             label = String.fromCharCode((num % 26) + 65) + label;
//             num = Math.floor(num / 26) - 1;
//           }
//           letters.push(label);
//         }
//         return letters;
//       }
      
//       const letters = generateLetters(30); // 26 + 26*26 = 702, enough for double letters (AA, AB, ..., ZZ)
    
//       if (!isOpen) {
//         return null;
//       }
    
//       return (
//         <div className="NamingModal-overlay">
//           <div className="NamingModal-content">
//             <h2>Select a Letter</h2>
//             <div className="letter-buttons">
//               {letters.map((letter) => (
//                 <button
//                   key={letter}
//                   className="letter-button"
//                   onClick={() => onSelectLetter(letter)}
//                 >
//                   {letter}
//                 </button>
//               ))}
//             </div>
//             <button className="naming_close-button" onClick={onClose}>
//               Close
//             </button>
//           </div>
//         </div>
//       );
//     };
    
//     export default NamingModal;
    

import React from 'react';
import './NamingSeatManagement.css';

function generateLabels(count) {
  const letters = [];
  for (let i = 0; i < count; i++) {
    let label = '';
    let num = i;
    while (num >= 0) {
      label = String.fromCharCode((num % 26) + 65) + label;
      num = Math.floor(num / 26) - 1;
    }
    letters.push(label);
  }
  return letters;
}

function NamingModal({ isOpen, onClose, onSelectLetter, usedLetters }) {
  const letters = generateLabels(30);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="namingModal-overlay">
      <div className="namingModal-content">
        <h2>Select a Letter</h2>
        <div className="letter-buttons">
          {letters.map((letter) => (
            <button
              key={letter}
              className="letter-button"
              onClick={() => onSelectLetter(letter)}
              disabled={usedLetters.includes(letter)}
              style={{ background: usedLetters.includes(letter) ? 'red' : '',
              color: usedLetters.includes(letter) ? 'white' : 'black' }}
            >
              {letter}
            </button>
          ))}
        </div>
        <button className="naming_close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default NamingModal;


