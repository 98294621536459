
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { IMAGE_URL } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewAds({targetID}) {
  const [ads, setAds] = useState(null);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetAds() {
      const { data } = await axiosInstance.get("ads/getSingleAds/" + targetID);
      setAds(data)
    }
    fetchAndSetAds();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {ads && (
         <>
         <h1>Poster Link</h1>
         <p>{ads?.link}</p>

         <h1>Poster Image</h1>
         <Image imgLink={ads.poster} imgAlt={"Card Front Image"} />   
       </>
      )}
    </div>
  );
}

export default ViewAds;
