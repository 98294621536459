import { useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";


function CreateTicketCategories({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) {


  const axiosInstance = useAxiosInstance();
  const [name, setName] = useState("");
  const [color, setColor] = useState("");


  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("color", `#${color}`);

   
    const response = await axiosInstance.post("ticket/categories", itemData);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
         <ShortTextInput
        label={`Category Name`}
        value={name}
        placeholder={`Enter Category Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Color Code`}
        value={color}
        placeholder={`Enter Color Code`}
        setState={setColor}
      />
     
     
      <FormSubmitButton text="Create Category" />
    </Form>
  );
}

export default CreateTicketCategories;
