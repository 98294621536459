export function generateSeats(rows, columns) {
  const newSeats = [];
  for (let j = 0; j < columns; j++) {
    for (let i = 0; i < rows; i++) {
      const rowNumber = i + 1;
      const columnNumber = j + 1;
      const seatLetter = String.fromCharCode(65 + j); // Convert column index to letter (A, B, C, ...)
      newSeats.push({
        seatId: `R${rowNumber}C${columnNumber}`, // Format seat ID as R1C1, R2C1, R1C2, R2C2, ...
        seatTrackerId: `${seatLetter}${rowNumber}`, // Format seatTrackerId as A1, B1, C1, ...
        row: rowNumber, // Row as number
        column: columnNumber, // Column as number
        status: "recliner" // Default seat type
      });
    }
  }
  return newSeats;
}

export function handleSeatClick(seatId, selectedSeatId, setSelectedSeatId) {
  setSelectedSeatId(seatId === selectedSeatId ? null : seatId);
}

export function handleSeatTypeChange(seatId, newType, setSeats, setColumnTypes, setRowTypes) {
  setSeats((prevSeats) =>
    prevSeats.map((seat) =>
      seat.seatId === seatId ? { ...seat, status: newType } : seat
    )
  );
}

export function handleRowCheck(rowIndex, newType, setSeats) {
  setSeats((prevSeats) =>
    prevSeats.map((seat) =>
      seat.row === rowIndex + 1 ? { ...seat, status: newType } : seat
    )
  );
}

export function handleColumnCheck(colIndex, newType, setSeats) {
  setSeats((prevSeats) =>
    prevSeats.map((seat) =>
      seat.column === colIndex + 1 ? { ...seat, status: newType } : seat
    )
  );
}

export function handleCheckboxClick(type, index, activeDropdown, setActiveDropdown, rowTypes, columnTypes, setRowTypes, setColumnTypes, setSeats) {
  if (activeDropdown.type === type && activeDropdown.index === index) {
    setActiveDropdown({ type: null, index: null });
  } else {
    setActiveDropdown({ type, index });
  }

  if (type === 'row') {
    if (rowTypes[index]) {
      setRowTypes((prev) => {
        const newTypes = { ...prev };
        delete newTypes[index];
        return newTypes;
      });
      handleRowCheck(index, "available", setSeats);
    }
  } else if (type === 'column') {
    if (columnTypes[index]) {
      setColumnTypes((prev) => {
        const newTypes = { ...prev };
        delete newTypes[index];
        return newTypes;
      });
      handleColumnCheck(index, "available", setSeats);
    }
  }
}

export function handleRowSelect(rowIndex, newType, setSeats, setRowTypes, setActiveDropdown) {
  if (newType) {
    setRowTypes((prev) => ({
      ...prev,
      [rowIndex]: newType
    }));
    setSeats((prevSeats) =>
      prevSeats.map((seat) =>
        seat.row === rowIndex + 1 ? { ...seat, status: newType } : seat
      )
    );
  } else {
    setRowTypes((prev) => {
      const newTypes = { ...prev };
      delete newTypes[rowIndex];
      return newTypes;
    });
    setSeats((prevSeats) =>
      prevSeats.map((seat) =>
        seat.row === rowIndex + 1 ? { ...seat, status: "available" } : seat
      )
    );
  }
  setActiveDropdown({ type: null, index: null });
}

export function handleColumnSelect(colIndex, newType, setSeats, setColumnTypes, setActiveDropdown) {
  if (newType) {
    setColumnTypes((prev) => ({
      ...prev,
      [colIndex]: newType
    }));
    setSeats((prevSeats) =>
      prevSeats.map((seat) =>
        seat.column === colIndex + 1 ? { ...seat, status: newType } : seat
      )
    );
  } else {
    setColumnTypes((prev) => {
      const newTypes = { ...prev };
      delete newTypes[colIndex];
      return newTypes;
    });
    setSeats((prevSeats) =>
      prevSeats.map((seat) =>
        seat.column === colIndex + 1 ? { ...seat, status: "available" } : seat
      )
    );
  }
  setActiveDropdown({ type: null, index: null });
}

export function handleButtonStatusClick(type, index, activeDropdown, setActiveDropdown, rowTypes, columnTypes, setRowTypes, setColumnTypes, setSeats) {
  if (activeDropdown.type === type && activeDropdown.index === index) {
    setActiveDropdown({ type: null, index: null });
  } else {
    setActiveDropdown({ type, index });
  }

  if (type === 'row') {
    if (rowTypes[index]) {
      handleRowSelect(index, null, setSeats, setRowTypes, setActiveDropdown);
    }
  } else if (type === 'column') {
    if (columnTypes[index]) {
      handleColumnSelect(index, null, setSeats, setColumnTypes, setActiveDropdown);
    }
  }
}
