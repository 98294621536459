import { useEffect, useState } from 'react';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';

import './orderEmail.css';
import { ORDERS_API } from '../../../../Utilities/APIs/APIs';

function OrderEmail({
  targetID,
  setShowDeleteSection,
  setShowModal,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  async function handleSendEmail() {
    const response = await axiosInstance.post(ORDERS_API +"sendOrderEmail/"+ targetID);
    if (response) {
      setShowDeleteSection(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div className='modal_delete_content'>
      <p>Are you sure you want to send an email for this order?</p>

      <div className='modal_group_buttons'>
        <button
          className='delete'
          onClick={() => {
            handleSendEmail();
          }}
        >
          Send Email
        </button>

        <button
          className='cancel'
          onClick={() => {
            setShowModal(false);
            setShowDeleteSection(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default OrderEmail;
