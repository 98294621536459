import { useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";



function CreateState({ setShowCreateForm, setShowModal, triggerFetch,countryId}) {

  const [name, setName] = useState("");

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("country",countryId)

    const response = await axiosInstance.post("states/getAllStateByCountryId/" + countryId, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`State Name`}
        value={name}
        placeholder={`Enter State Name`}
        setState={setName}
      />
      <FormSubmitButton text="Create State" />
    </Form>
  );
}

export default CreateState;
