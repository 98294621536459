import ContentWrapper from "../ContentWrapper/ContentWrapper"
import TopHeading from "../TopHeading/TopHeading"
import EventCard from "./EventCard/EventCard"
import SeeMoreButton from "./SeeMoreButton/SeeMoreButton"


function EventDetails({ nowSellingEvents }) {
    return (
        <div>

            <TopHeading heading={"Event Details"} />

            <ContentWrapper>
                {
                    nowSellingEvents?.slice(0, 4)?.map(event => <EventCard key={event._id} event={event} />)
                }

                <SeeMoreButton />
            </ContentWrapper>

        </div>
    )
}

export default EventDetails