import { useEffect, useState } from "react";

import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateHallSeat({
  targetID,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [status, setStatus] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetStatus() {
      const { data } = await axiosInstance.get(
        "theaters/halls/hallSeats/getSingleSeat/" + targetID
      );
      setStatus(data?.status);
    }
    fetchAndSetStatus();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = { status };
    const response = await axiosInstance.patch(
      "theaters/halls/hallSeats/updateSeat/" + targetID,
      itemData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Select Status"} value={status} setState={setStatus}>
        <SelectOption optionText={"Select Status"} optionValue={""} />
        <SelectOption optionText={"Premium"} optionValue={"premium"} />
        <SelectOption optionText={"Recliner"} optionValue={"recliner"} />
        <SelectOption optionText={"Standard"} optionValue={"standard"} />
        <SelectOption optionText={"Wheelchair"} optionValue={"wheelchair"} />
        <SelectOption optionText={"Kids"} optionValue={"kids"} />
      </SelectInput>
      <FormSubmitButton text="Update Seat" />
    </Form>
  );
}

export default UpdateHallSeat;
