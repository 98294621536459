import { useState } from "react";
import SingleSeatModal from "../Entrances/NamingSeatMangement/SingleSeatModal/SingleSeatModal";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";


function UpdateNamingSeat({ rows, columns, setSeats, seats, triggerFetch }) {
    const [selectedSeatId, setSelectedSeatId] = useState(null);
    const [isSingleSeatModalOpen, setIsSingleSeatModalOpen] = useState(false);
    const [singleSeatName, setSingleSeatName] = useState('');
    const axiosInstance = useAxiosInstance()

    function handleSeatClickWrapper(seatId) {
        setSelectedSeatId(seatId === selectedSeatId ? null : seatId)
        setIsSingleSeatModalOpen(true)
    };


    async function handleSingleSeatSave() {

        const response = await axiosInstance.patch(
            "theaters/halls/hallSeats/updateSeat/" + selectedSeatId,
            { seatName: singleSeatName }
        );

        if (response) {
            setIsSingleSeatModalOpen(false);
            setSingleSeatName('');
            triggerFetch()

        }
    
    };

    return (
        <div className="seat-management">

            <SingleSeatModal
                isOpen={isSingleSeatModalOpen}
                onClose={() => setIsSingleSeatModalOpen(false)}
                onSave={handleSingleSeatSave}
                seatName={singleSeatName}
                setSeatName={setSingleSeatName}
            />
            {seats?.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: rows }, (_, rowIndex) => (
                            <tr key={rowIndex}>

                                {Array.from({ length: columns }, (_, colIndex) => {
                                    const seat = seats.find(
                                        (seat) => seat.row === rowIndex + 1  && seat.column === colIndex + 1
                                    );
                                    return (
                                        <td key={colIndex}>
                                            <button
                                                type="button"
                                                className={`square-button ${seat?.status}`}
                                                onClick={() => {
                                                    handleSeatClickWrapper(seat?._id);
                                                }}
                                                style={{ fontSize: "8px", color: "white" }}
                                            >
                                                {seat?.seatName}
                                            </button>
                                        </td>
                                    );
                                })}

                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default UpdateNamingSeat;
