import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import { RiInboxArchiveLine } from "react-icons/ri";
import Modal from "../../Partials/Elements/Modal/Modal";
import ArchiveOrder from "../Orders/archiveOrder/archiveOrder";
import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import SearchMethod from "./SearchMethod/SearchMethod";
import OrderEmail from "../Orders/orderEmail/orderEmail";
import { MdOutlineEmail } from "react-icons/md";
import OrderInfo from "../../Partials/Elements/OrderInfo/OrderInfo";

function SearchOrder() {

    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState(null);
    const [targetID, setTargetID] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
    const [showSendEmail, setShowSendEmail] = useState(false);
    const [searchMethod, setSearchMethod] = useState("orderId")
    const axiosInstance = useAxiosInstance()

    async function searchOrder() {


        let itemData = {}

        if (searchMethod === "orderId") {
            itemData.orderId = searchText
        } else {
            itemData.transactionId = searchText
        }

        const response = await axiosInstance.post("orders/searchOrderById", itemData)

        if (response) {
            setSearchResult(response.data);
        }
    }

    const { order, seats } = searchResult || {};

    function handleClose() {
        setShowModal(false);
        setShowDeleteSection(false);
        setShowSendEmail(false)
    }

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    function handleArchive(order) {
        setShowModal(true);
        setShowDeleteSection(true);
        setTargetID(order._id);
    }

    function handleSendEmail() {
        setShowModal(true);
        setShowSendEmail(true);
        setTargetID(order._id);
    }

    useEffect(() => {
        setSearchResult("")
    }, [toggleFetch])


    return (
        <>
            <div>
                <SearchMethod searchMethod={searchMethod} setSearchMethod={setSearchMethod} setSearchResult={setSearchResult} setSearchText={setSearchText} />


                {
                    searchMethod && <>
                        <h1>Search Order</h1>

                        <input
                            type="text"
                            placeholder="Search Order"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button style={{ backgroundColor: 'yellow' }} onClick={searchOrder}>Search</button>
                        <br />
                        <h1>Search Result:</h1>
                        {
                            searchResult &&
                            // <>
                            //     <p>ID: {order.orderId}</p>
                            //     <p>Order DateTime: {order.createdAt}</p>
                            //     <p>Email: {order.email}</p>
                            //     <p>Name: {order.name}</p>
                            //     <p>Phone: {order.mobileNumber}</p>
                            //     <p>Amount: {order.amount}</p>
                            //     <p>Transaction ID: {order.transactionId}</p>
                            //     <p>Event: {order.event.name}</p>
                            //     <p>Show: {
                            //         `${order.show.theater.name}, ${order.show.hall.name}, ${new Date(order.show.date).toDateString()
                            //         } ${order.show.startTime}`
                            //     }</p>
                            //     <p>Seats: {JSON.stringify(seats)}</p>


                            //     {order?.isActive && (
                            //         <button
                            //             className="archive_btn"
                            //             dataTip={order?.isActive ? "Archive" : "Unarchive"}
                            //             dataFor='Archive Order'
                            //             onClick={() => handleArchive(order)}
                            //         >
                            //             Archive   <RiInboxArchiveLine />
                            //         </button>
                            //     )}

                            //     <button
                            //     className="archive_btn"
                            //         onClick={() => handleSendEmail(order)}

                            //     >
                            //         Send Email  <MdOutlineEmail />
                            //     </button>

                            // </>

                            <OrderInfo
                                order={order}
                                seats={seats}
                                handleArchive={handleArchive}
                                handleSendEmail={handleSendEmail}
                                searchOrder={true}
                            />
                        }
                    </>


                }
            </div >

            {showModal && showDeleteSection && (
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={showDeleteSection && `Cancel Order`}
                >
                    <ArchiveOrder
                        setShowDeleteSection={setShowDeleteSection}
                        setShowModal={setShowModal}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )
            }

            {showModal && showSendEmail && (
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={showSendEmail && `Send Email`}
                >
                    <OrderEmail
                        setShowDeleteSection={setShowSendEmail}
                        setShowModal={setShowModal}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}
        </>
    )
}

export default SearchOrder