
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";


function ViewTheater({targetID}) {
  const [theater, setTheater] = useState(null);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetAds() {
      const { data } = await axiosInstance.get("theaters/getSingleTheater/" + targetID);
      setTheater(data)
    }
    fetchAndSetAds();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {theater && (
         <>
         <h1>Theater Name</h1>
         <p>{theater?.name}</p>

         <h1>Address</h1>
         <p>{theater?.address}</p>

         <h1>City</h1>
         <p>{theater?.city?.name}</p>

         <h1>State</h1>
         <p>{theater?.state?.name}</p>

         <h1>ZipCode</h1>
         <p>{theater?.zipCode}</p>

        
       </>
      )}
    </div>
  );
}

export default ViewTheater;
