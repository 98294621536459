import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { useParams } from "react-router-dom";


function UpvoteEventUser() {

  const { eventId,eventName } = useParams()
  const [customers, setCustomers] = useState(null);


  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEvents() {
      const { data } = await axiosInstance.get(`events/upvoteEventUser?eventID=${eventId}`);
      setCustomers(data);
    }
    fetchAndSetEvents();
  }, [ axiosInstance, eventId]);


  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{` Voted Customer  - ${eventName} (${customers?.length ? customers?.length : 0} in total)`}</h1>
          </div>

        </div>

        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th=" Name" />
            <CRUDth th="Email" />
            <CRUDth th="Date" />
          </CRUDTableHeader>
          <tbody>

            { customers && customers?.map((customer) => (
              <CRUDTableRow key={customer._id}>
                <ShortTextCell text={customer?.customer?.name} />

                <ShortTextCell text={customer?.customer?.email} />
                <ShortTextCell text={new Date (customer?.createdAt).toLocaleDateString()} />

              </CRUDTableRow>
            ))}

          </tbody>
        </CRUDTable>
      </div>
    </>
  );
}

export default UpvoteEventUser;
