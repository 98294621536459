import HallTabs from "../HallTabs/Halltabs"
import Tabs from "../Tabs/Tabs"



function ViewHallButton({activeTab,handlePrev,handleNext,handleSubmit,rows,columns,name,isSubmitDisabled}) {
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "end",
    }}
    >
    {/* <HallTabs  activeTab={activeTab} /> */}
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <button
      type="button"
      onClick={handlePrev}
        style={{
          border: "1px solid lightgray",
          color: "white",
          textAlign: "center",
          width: "200px",
          padding: "10px",
          background: "var(--gray-8)",
          borderRadius: "10px",
          fontSize: "18px",
          fontWeight: "600",
        }}
        disabled={activeTab === 1}
      >
        Seating View
      </button>
    
   
      <button
      style={{
        textAlign: "center",
        width: "200px",
        padding: "10px",
        background: "var(--primary-color)",
        borderRadius: "10px",
        fontSize: "18px",
        fontWeight: "600",
      }}
      type="button"
      onClick={handleNext}
      disabled={activeTab === 2}
    >
    Naming View
    </button>
    

    </div>
    </div>
  )
}


export default ViewHallButton


