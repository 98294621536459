import './SelectInput.css'

function SelectInput({ value, setState, children, label }) {
  return (
    <>
      <label className="input_field_label caption bold">{label}</label>
      <select
        className='input_field'
        value={value}
        onChange={e => setState(e.target.value)}
      >
        {children}
      </select>
    </>

  )
}

export default SelectInput