import { useEffect, useState } from "react";

import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";

function CreateEventPrice({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  showsId,
}) {
  const axiosInstance = useAxiosInstance();
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("");


  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   async function fetchAndSetHeroSliders() {
  //     const { data } = await axiosInstance.get(`/ticket/categories`);
  //     setCategories(data);
  //   }
  //   fetchAndSetHeroSliders();
  // }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("price", price);
    itemData.append("status", status);
    itemData.append("show", showsId);

    const response = await axiosInstance.post(
      "events/shows/showsPrice/createShowPriceByShowId/" + showsId,
      itemData
    );
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  // Premium
  // Recliner
  // Standard
  // Wheelchair
  // Kids
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Select Status"} setState={setStatus} value={status}>
        <SelectOption optionText={"Select Status"} optionValue={""} />
        <SelectOption optionText={"Premium"} optionValue={"premium"} />
        <SelectOption optionText={"Recliner"} optionValue={"recliner"} />
        <SelectOption optionText={"Standard"} optionValue={"standard"} />
        <SelectOption optionText={"Wheelchair"} optionValue={"wheelchair"} />
        <SelectOption optionText={"Kids"} optionValue={"kids"} />

      </SelectInput>

      <NumberInput
        label={`Price`}
        value={price}
        placeholder={`Enter Price`}
        setState={setPrice}
      />

      <FormSubmitButton text="Create Shows Price" />
    </Form>
  );
}

export default CreateEventPrice;
