import { useContext, useEffect, useState } from "react";
import BackButton from "../../Partials/Elements/BackButton/BackButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import ArchiveItem from "../../Partials/Layouts/ArchiveItem/ArchiveItem";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import ArchiveButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import FilterSelect from "../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CreateTicketCategories from "./CreateTicketCategories";
import UpdateTicketCategories from "./UpdateTicketCategories";
import AppContext from "../../../Context/AppContext";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function TicketCategories() {
  const [categories, setCategories] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(
        `/ticket/categories?filter=${filter}`
      );
      setCategories(data);
    }
    fetchAndSetHeroSliders();
  }, [toggleFetch, filter, axiosInstance]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Categories (${categories?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Category"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Color" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {categories?.map((category) => (
              <CRUDTableRow key={category._id}>
                <ShortTextCell text={category?.name} />
                <td
                  style={{
                    width:'15px',
                    maxWidth: "20px",
                    height: "20px",
                    borderRadius: "10px",
                    backgroundColor: category?.color,
                  }}
                ></td>

                <td className="action_button_cell">
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={category._id}
                    setTargetID={setTargetID}
                  />

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={category._id}
                    isArchive={category?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Category`) ||
            (showUpdateForm && `Update Category`) ||
            (showDeleteSection && `Archive Category`)
          }
        >
          {showCreateForm && (
            <CreateTicketCategories
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateTicketCategories
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={"ticket/categories/archiveTicektCategory/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default TicketCategories;
