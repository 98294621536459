
import { useEffect, useState } from "react";
import ViewHallButton from "./ViewHallButton";
// import SeatManagement from "../SeatManageMent";
import NamingSeatManagement from "../NamingSeatMangement/NamingSeatManagement";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import SeatManagement from "../SeatManagement/SeatManagement";


function ViewHall({
  targetId
}) {
  const [seats, setSeats] = useState([]);
  const [name, setName] = useState("");
  const [rows, setRows] = useState("");
  const [columns, setColumns] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const axiosInstance = useAxiosInstance();


  useEffect(() => {

    async function fetchAndSetHallSeats() {
      try {
        const { data } = await axiosInstance.get("/theaters/halls/getSingleHallWithHallSeat/" + targetId);
        setRows(data?.hall?.numberOfRows)
        setColumns(data?.hall?.numberOfColumns)
        setSeats(data?.hallSeats);
      } catch (error) {
        console.error("Error fetching halls:", error);
      }
    }
    fetchAndSetHallSeats()

  }, [axiosInstance, targetId]);


  function handleNext() {
    setActiveTab(2)
  };
  function handlePrev() {
    setActiveTab(1)
  };

  const containerStyle = {
    height: "75vh",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  };


  return (
    <>

      <div>
        <div className="scroll-container" style={containerStyle}>
          {activeTab === 1 && (
            <>
              <SeatManagement
                view
                rows={rows}
                columns={columns}
                seats={seats}
                setSeats={setSeats}
              />
            </>
          )}

          {
            activeTab === 2 && (
              <>

                <NamingSeatManagement
                  view
                  rows={rows}
                  columns={columns}
                  seats={seats}
                  setSeats={setSeats}
                />
              </>
            )
          }
        </div>
      </div>

      <div>
        <ViewHallButton
          rows={rows}
          columns={columns}
          name={name}
          activeTab={activeTab}
          handlePrev={handlePrev}
          handleNext={handleNext}
        />
      </div>
    </>
  );
}

export default ViewHall;
