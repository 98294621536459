import { useParams } from "react-router-dom";
import { FiImage } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { useState, useEffect, useContext } from "react";
// import { useSelector } from "react-redux"

import AppContext from "../../../../Context/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import CreateHall from "./CreateHall";
import HallModal from "../../../Partials/Elements/HallModal/HallModal";
import ViewHall from "./ViewHall/ViewHall";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import UpdateHallSeat from "../UpdateHallSeat/UpdateHallSeat";
import { RiEditBoxLine } from "react-icons/ri";
import UpdateHall from "./UpdateHall.js";

function Entrances() {
  const { theaterId } = useParams();
  const [entrances, setEntrances] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  // console.log(targetID, 'targetID');
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUpdateSeatSection, setShowUpdateSeatSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(
        `/theaters/halls/getAllHallByTheaterId/${theaterId}?filter=${filter}`
      );
      setEntrances(data);
    }
    fetchAndSetHeroSliders();
  }, [toggleFetch, filter, theaterId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Halls (${entrances?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Hall"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {entrances?.map((entrance) => (
              <CRUDTableRow key={entrance._id}>
                <ShortTextCell text={entrance?.name} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={entrance._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={entrance._id}
                    setTargetID={setTargetID}
                  />

                  {/* <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateSeatSection}
                    targetID={entrance?._id}
                    setTargetID={setTargetID}
                  /> */}

                  <CRUDButton
                    dataTip="Update Hall Seat"
                    dataFor="Update Hall Seat"
                    handleClick={() => {
                      setShowModal(true);
                      setShowUpdateSeatSection(true);
                      setTargetID(entrance?._id);
                    }}
                  >
                    <RiEditBoxLine />
                  </CRUDButton>

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={entrance._id}
                    isArchive={entrance?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />

                  <CRUDButton
                    dataTip="Seat Manage"
                    dataFor="Seat Manage"
                    handleClick={() => {
                      navigate(`/halls/seatManagement/${entrance?._id}`);
                    }}
                  >
                    <FiImage />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      
      {showModal && showUpdateForm && (
        <Modal
          handleClose={handleClose}
          modalHeading={showUpdateForm && `Update Hall`}
        >
          {showUpdateForm && (
            <UpdateHall
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showUpdateSeatSection && (
        <HallModal setShowModalContent={setShowUpdateSeatSection}>
          {showUpdateSeatSection && (
            <UpdateHallSeat
              employee={employee}
              targetID={targetID}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
        </HallModal>
      )}

      {showModal && showDeleteSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showDeleteSection && `Archive Hall`}
        >
          <ArchiveItem
            api={"theaters/halls/archiveHall/"}
            targetID={targetID}
            setShowModal={setShowModal}
            setShowDeleteSection={setShowDeleteSection}
            triggerFetch={triggerFetch}
            isArchive={archive}
          />
        </Modal>
      )}

      {showModal && showCreateForm && (
        <HallModal setShowModalContent={setShowCreateForm}>
          <CreateHall
            employee={employee}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
            theaterId={theaterId}
          />
        </HallModal>
      )}

      {showModal && showViewSection && (
        <HallModal setShowModalContent={setShowViewSection}>
          <ViewHall
            targetId={targetID}
            employee={employee}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </HallModal>
      )}
    </>
  );
}

export default Entrances;
