import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Image from "../../Partials/Elements/Image/Image";
import { IMAGE_URL } from "../../../Utilities/APIs/APIs";


function ViewEvent({ targetID }) {
  const [eventInfo, setEventInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEventInfo() {
      const { data } = await axiosInstance.get('events/getSingleEvent/' + targetID);
      setEventInfo(data);
    }
    fetchAndSetEventInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">

      {eventInfo && (
        <>
          <h1>Event Name</h1>
          <p>{eventInfo?.name}</p>

          <h1>Release Type</h1>
          <p>{eventInfo?.releaseType}</p>

          {eventInfo?.theatricalLink && 
          <>
            <h1>Theatrical Page link</h1>
            <p>{eventInfo?.theatricalLink}</p>
          </>}

          <h1>Description</h1>
          <p>{eventInfo.description}</p>

          <h1>Release Date</h1>
          <p>{new Date(eventInfo.releaseDate).toLocaleDateString()}</p>

          <h1>Organizer</h1>
          <p>{eventInfo.organizer}</p>


          <h1>Type</h1>
          <p>{eventInfo.type}</p>

          <h1>Duration</h1>
          <p>{eventInfo.duration}</p>

          <h1>Location</h1>
          <p>{eventInfo.location}</p>

          <h1>Trailer Video link</h1>
          <p>{eventInfo?.trailerVideoLink}</p>

          <h1>Card Image</h1>
          <Image imgLink={eventInfo.cardImage} imgAlt={"Card Front Image"} />

          <h1>Banner Image</h1>
          <Image imgLink={eventInfo.bannerImage} imgAlt={"Card Banner Image"} />

        </>
      )}
    </div>
  );
}

export default ViewEvent;
