import { useState, useEffect, useContext } from "react";
// import { useSelector } from "react-redux"

import AppContext from "../../../Context/AppContext";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ArchiveButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import ArchiveItem from "../../Partials/Layouts/ArchiveItem/ArchiveItem";
import CreateTheater from "./CreateTheater";
import UpdateTheater from "./UpdateTheater";
import ViewTheater from "./ViewTheater";
import { useNavigate } from "react-router-dom";
import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiImage } from "react-icons/fi";

function Theaters() {
  const [theaters, setTheaters] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(`/theaters?filter=${filter}`);
      setTheaters(data);
    }
    fetchAndSetHeroSliders();
  }, [toggleFetch, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Theaters (${theaters?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Theater"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Address" />
            <CRUDth th="Country" />
            <CRUDth th="State" />
            <CRUDth th="City" />
            <CRUDth th="ZipCode" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {theaters?.map((theater) => (
              <CRUDTableRow key={theater._id}>
                <ShortTextCell text={theater?.name} />
                <ShortTextCell text={theater?.address} />
                <ShortTextCell text={theater?.country?.name} />
                <ShortTextCell text={theater?.city?.name} />
                <ShortTextCell text={theater?.state?.name} />
                <ShortTextCell text={theater?.zipCode} />
                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={theater._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={theater._id}
                    setTargetID={setTargetID}
                  />

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={theater._id}
                    isArchive={theater?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                  <CRUDButton
                    dataTip=" Hall"
                    dataFor=" Halls"
                    handleClick={() => navigate("/entrances/" + theater._id)}
                  >
                    <FiImage />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
        theater
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Theater`) ||
            (showUpdateForm && `Update Theater`) ||
            (showDeleteSection && `Archive Theater`)
          }
        >
          {showCreateForm && (
            <CreateTheater
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateTheater
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={"theaters/archiveTheater/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Theater`}
        >
          <ViewTheater targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
}

export default Theaters;
