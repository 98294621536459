import { useContext, useEffect, useState } from "react";
import { FaUserLarge } from "react-icons/fa6";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../Context/AppContext";
import BackButton from "../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ArchiveButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import CreateEvent from "./CreateEvent";
import UpdateEvent from "./UpdateEvent";
import ArchiveItem from "../../Partials/Layouts/ArchiveItem/ArchiveItem";
import ViewEvent from "./ViewEvent";
import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiImage } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { RiEditCircleLine, RiFunctionLine } from "react-icons/ri";
import UpdateStatus from "./UpdateStatus";

function Events() {

  const { status } = useParams()
  const [events, setEvents] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [showStatusSection, setShowStatusSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const axiosInstance = useAxiosInstance();
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const { employee } = useContext(AppContext);
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchAndSetEvents() {
      const { data } = await axiosInstance.get(`events?filter=${filter}&status=${status}`);
      setEvents(data);
    }
    fetchAndSetEvents();
  }, [toggleFetch, axiosInstance, filter, status]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`${status === "nowSelling" && "Now Selling" || status === "upcoming" && "Upcoming" || status === "past" && "Past" || status === "voteToBring" && "Vote to Bring"} Events (${events?.length ? events?.length : 0} in total)`}</h1>
          </div>

          <FilterSelect filter={filter} setFilter={setFilter} />

          <CreateButton
            screenTopicSingular="Event"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />

            <CRUDth th="Organizer" />
            <CRUDth th="Type" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {events?.map((event) => (
              <CRUDTableRow key={event._id}>
                <ShortTextCell text={event.name} />

                <ShortTextCell text={event.organizer} />
                <ShortTextCell text={event.type} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={event._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={event._id}
                    setTargetID={setTargetID}
                  />

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={event._id}
                    isArchive={event?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />

                  <CRUDButton
                    dataTip=" Event Manage"
                    dataFor=" Event Manage Item"
                    handleClick={() =>
                      navigate("/eventsScreens/" + event._id)
                    }
                  >
                    <FiImage />
                  </CRUDButton>

                  <CRUDButton
                    dataTip=" Status"
                    dataFor=" Status"
                    handleClick={() => {
                      setShowModal(true)
                      setShowStatusSection(true)
                      setTargetID(event._id)
                    }
                    }
                  >
                    <RiEditCircleLine />

                  </CRUDButton>
                  {
                    event?.status === "voteToBring" &&
                    <CRUDButton
                      dataTip="Voted User"
                      dataFor="Voted"
                      handleClick={() =>
                        navigate(`/event/upvoted/user/${event?.name}/${event?._id}`)
                      }

                    >
                      <FaUserLarge />
                    </CRUDButton>
                  }
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Event`) ||
            (showUpdateForm && `Update Event`) ||
            (showDeleteSection && `Archive Event`) ||
            (showStatusSection && `Update Status`)
          }
        >
          {showCreateForm && (
            <CreateEvent
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              status={status}
            />
          )}
          {showUpdateForm && (
            <UpdateEvent
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showDeleteSection && (
            <ArchiveItem
              api={"events/archiveEvent/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}

          {showStatusSection && (
            <UpdateStatus
              targetID={targetID}
              setShowModal={setShowModal}
              setShowStatusSection={setShowStatusSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Event`}
        >
          <ViewEvent targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
}

export default Events;
