
import { useEffect, useState } from "react";


import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from './../../../Partials/Layouts/Forms/Form';
import TimeInput from "../../../Partials/Layouts/Forms/FormInputs/TimeInput/TimeInput";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import CreateShowsButton from "./CreateShowsButton/CreateShowsButton";
import NamingSeatManagement from "../../Theaters/Entrances/NamingSeatMangement/NamingSeatManagement";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";

function CreateShows({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  eventId
}) {

  const axiosInstance = useAxiosInstance();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [date, setDate] = useState("");
  const [halls, setHalls] = useState([])
  const [hall, setHall] = useState("")

  const [theaters, setTheaters] = useState([])
  const [theater, setTheater] = useState("")

  const [cities, setCities] = useState([])
  const [city, setCity] = useState("")

  const [states, setStates] = useState([])
  const [state, setState] = useState("")

  const [activeTab, setActiveTab] = useState(1);
  const [seats, setSeats] = useState([]);
  const [rows, setRows] = useState("");
  const [columns, setColumns] = useState("");

  const [country, setCountry] = useState()
  const [countries, setCountries] = useState([])

  useEffect(() => {

    async function fetchAndSetCountries() {
      const { data } = await axiosInstance.get("/countries?filter=active")
      setCountries(data)
    }
    fetchAndSetCountries()

  
  }, [axiosInstance])


  useEffect(()=>{
    async function fetchAndSetStates() {
      const { data } = await axiosInstance.get(`/states/getAllStateByCountryId/${country}?filter=active` )
      setStates(data)
    }

    if (country) {
      fetchAndSetStates()
    }

  },[axiosInstance,country])

  useEffect(()=>{
    async function fetchAndSetCities() {
      const { data } = await axiosInstance.get(`/states/cities/allCitiesByStateId/${state}?filter=active`)
      setCities(data)
    }
    if (state) {
      fetchAndSetCities()
    }
  },[axiosInstance,state])

  useEffect(()=>{
    async function fetchAndSetTheater() {
      const { data } = await axiosInstance.get("/theaters/getTheaterByCity/" + city)
      setTheaters(data)
    }
    if (city) {
      fetchAndSetTheater()
    }
  },[axiosInstance,city])

  useEffect(() => {
    async function fetchAndSetHall() {
      const { data } = await axiosInstance.get(`/theaters/halls/getAllHallByTheaterId/${theater}?filter=active`)
      setHalls(data)
    }
    if (theater) {
      fetchAndSetHall()
    }
  }, [axiosInstance, theater])

  useEffect(() => {

    async function fetchAndSetHallSeats() {
      try {
        const { data } = await axiosInstance.get("/theaters/halls/getSingleHallWithHallSeat/" + hall);
        setRows(data?.hall?.numberOfRows)
        setColumns(data?.hall?.numberOfColumns)
        setSeats(data?.hallSeats);
      } catch (error) {
        console.error("Error fetching halls:", error);
      }
    }

    if (hall) {
      fetchAndSetHallSeats();
    }
  }, [axiosInstance,hall]);


  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrev = () => {
    setActiveTab((prev) => {
      return prev > 1 ? prev - 1 : prev;
    });
  };


  async function handleSubmit(e) {
    e.preventDefault();

    const formattedSeats = seats.map(seat => ({
      seatName: seat.seatName,
      status: seat.status,
      row: seat.row,
      column: seat.column,
    }));

    const itemData = {
      startTime,
      endTime,
      event: eventId,
      theater,
      city,
      state,
      hall,
      date,
      country,
      seats: formattedSeats
    };

    const response = await axiosInstance.post("events/shows/getAllShowsByEventId/" + eventId, itemData);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  const containerStyle = {
    height: "75vh",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="scroll-container" style={containerStyle}>
          <div>
            {activeTab === 1 && (
              <>

                <SelectInput value={country} setState={setCountry} label={"Select Country"}>
                  <SelectOption optionText={"Select Country"} optionValue={""} />

                  {
                    countries && countries.length > 0 && countries?.map(country =>
                      <SelectOption key={country._id} optionText={country?.name} optionValue={country?._id} />
                    )
                  }
                </SelectInput>

               
                <SelectInput value={state} setState={setState} label={"Select State"}>
                  <SelectOption optionText={"Select State"} optionValue={""} />

                  {
                    states && states.length > 0 && states?.map(state =>
                      <SelectOption key={state._id} optionText={state?.name} optionValue={state?._id} />
                    )
                  }
                </SelectInput>

                <SelectInput value={city} setState={setCity} label={"Select City"}>
                  <SelectOption optionText={"Select City"} optionValue={""} />

                  {
                    cities && cities.length > 0 && cities?.map(city =>
                      <SelectOption key={city._id} optionText={city?.name} optionValue={city?._id} />
                    )
                  }
                </SelectInput>

                <SelectInput value={theater} setState={setTheater} label={"Select Theater"}>
                  <SelectOption optionText={"Select Theater"} optionValue={""} />

                  {
                    theaters && theaters.length > 0 && theaters?.map(theatar =>
                      <SelectOption key={theatar._id} optionText={theatar?.name} optionValue={theatar?._id} />
                    )
                  }
                </SelectInput>


                <SelectInput value={hall} setState={setHall} label={"Select Hall"}>
                  <SelectOption optionText={"Select Hall"} optionValue={""} />

                  {
                    halls && halls.length > 0 && halls?.map(hall =>
                      <SelectOption key={hall._id} optionText={hall?.name} optionValue={hall?._id} />
                    )
                  }
                </SelectInput>

                <DateInput
                  label={`Show Date`}
                  value={date}
                  placeholder={`Event Show Date`}
                  setState={setDate}
                />

                <TimeInput
                  label={`Start Time`}
                  value={startTime}
                  placeholder={`Event Start Time`}
                  setState={setStartTime}
                />
                <TimeInput
                  label={`End Time`}
                  value={endTime}
                  placeholder={`Event End Time`}
                  setState={setEndTime}
                />
      
              </>
            )}

            {activeTab === 2 && (
              <>
                <NamingSeatManagement
                  rows={rows}
                  columns={columns}
                  seats={seats}
                  setSeats={setSeats}
                  shows
                  view
                />
              </>
            )}
          </div>
        </div>

        <div>
          <CreateShowsButton
            startTime={startTime}
            date={date}
            hall={hall}
            city={city}
            state={state}
            theater={theater}
            activeTab={activeTab}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleSubmit={handleSubmit}
          />
        </div>

      </Form>
    </>
  );
}

export default CreateShows;



