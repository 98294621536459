

import { useState, useEffect } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../Partials/Elements/BackButton/BackButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";

function ToofanAtHoytsInAustralia() {

    const [toofanAtHoytsInAustralias, setToofanAtHoytsInAustralias] = useState(null);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetToofanAtHoytsInAustralias() {
            const { data } = await axiosInstance.get('/getAllToofanAtHoytsInAustralia');
            setToofanAtHoytsInAustralias(data);
        }
        fetchAndSetToofanAtHoytsInAustralias();
    }, [axiosInstance,]);

    return (
        <>
            <div className="crud_holder">
                <div className="body_header">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                        <h1 className="heading">{`Toofan At HoytsIn Australias (${toofanAtHoytsInAustralias?.length > 0 ? toofanAtHoytsInAustralias?.length : 0} in total)`}</h1>
                    </div>
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th="Name" />
                        <CRUDth th="Email" />
                        <CRUDth th="Phone Number" />
                        <CRUDth th="Created Date" />
                    </CRUDTableHeader>
                    <tbody>
                        {toofanAtHoytsInAustralias && toofanAtHoytsInAustralias?.map((toofanAtHoytsInAustralia) => (
                            <CRUDTableRow key={toofanAtHoytsInAustralia._id}>
                                <ShortTextCell text={toofanAtHoytsInAustralia?.name} />
                                <ShortTextCell text={toofanAtHoytsInAustralia?.email} />
                                <ShortTextCell text={toofanAtHoytsInAustralia?.mobileNumber} />
                                <ShortTextCell
                                    text={toofanAtHoytsInAustralia?.createdAt}
                                />

                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>


        </>
    );
}

export default ToofanAtHoytsInAustralia;


