
import { useEffect, useState } from "react";


import UpdateSeatStatus from "./UpdateSeatStatus";
import UpdateNamingSeat from "./UpdateNamingSeat";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import ViewHallButton from "../Entrances/ViewHall/ViewHallButton";

function UpdateHallSeat({
    targetID
}) 
{
  const [seats, setSeats] = useState([]);
  const [name, setName] = useState("");
  const [rows, setRows] = useState("");
  const [columns, setColumns] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [toggleFetch, setToggleFetch] = useState(false);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {

    async function fetchAndSetHallSeats() {
      try {
        const { data } = await axiosInstance.get("/theaters/halls/getSingleHallWithHallSeat/" + targetID);
        setRows(data?.hall?.numberOfRows)
        setColumns(data?.hall?.numberOfColumns)
        setSeats(data?.hallSeats);
      } catch (error) {
        console.error("Error fetching halls:", error);
      }
    }
    fetchAndSetHallSeats()
   
  }, [axiosInstance,targetID,toggleFetch]);


  function handleNext  ()  {
  setActiveTab(2)
  };
  function handlePrev  ()  {
   setActiveTab(1)
  };

  const containerStyle = {
    height: "75vh",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  };

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }


  return (
    <>

        <div>
          <div className="scroll-container" style={containerStyle}>
            {activeTab === 1 && (
              <>
                <UpdateSeatStatus
                  rows={rows}
                  columns={columns}
                  seats={seats}
                  setSeats={setSeats}
                  triggerFetch={triggerFetch}
                />
              </>
            )}            

            {
              activeTab === 2 && (
                <>     
                <UpdateNamingSeat
                rows={rows}
                columns={columns}
                seats={seats}
                setSeats={setSeats}
                triggerFetch={triggerFetch}
                />
                </>
              )
            }
          </div>
        </div>

        <div>
          <ViewHallButton
            rows={rows}
            columns={columns}
            name={name}
            activeTab={activeTab}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        </div>
    </>
  );
}

export default UpdateHallSeat;
