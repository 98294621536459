import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useEffect, useState } from "react";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { RiSearchLine } from "react-icons/ri";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import Pagination from "../../../../Partials/Elements/Pagination/Pagination";
import UpdateHallSeat from "./UpdateHallSeat";


function HallSeats() {
    const { hallId } = useParams();
    const [seats, setSeats] = useState(null);
    const [search, setSearch] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
    const axiosInstance = useAxiosInstance();

    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalHallSeat, setTotalHallSeat] = useState(1);

    useEffect(() => {
        async function fetchAndSetSeats() {
            const { data } = await axiosInstance.get(
                `/theaters/halls/hallSeats/getAllHallSeatByHallId/${hallId}?search=${search}&page=${currentPage}`
            );
            setSeats(data.seats);
            setTotalPages(data.totalPages);
            setTotalHallSeat(data.totalHallSeat)
        }
        fetchAndSetSeats();
    }, [toggleFetch, hallId, search, currentPage]);

    function handlePageChange(page) {
        setCurrentPage(page);
    }


    function handleClose() {
        setShowModal(false);
        setShowUpdateForm(false);
    }

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <>
            <div className="crud_holder">
                <div className="body_header hall">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                        <h1 className="heading">
                            {`Hall Seat (${seats ? seats.length : 0} out of ${totalHallSeat})`}
                        </h1>
                    </div>

                    <div>
                        <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="input_field"
                            placeholder="Search"
                            style={{ width: "400px" }}
                        />
                        {/* <RiSearchLine
                            style={{
                                width: "20px",
                                height: "20px",
                                marginLeft: "-50px",
                                color: "var(--white-2)",
                            }}
                        /> */}
                    </div>
                </div>

                <CRUDTable hallSeats>
                    <CRUDTableHeader>
                        <CRUDth th="Seat Name" />
                        <CRUDth th="Seat Id" />
                        <CRUDth th="Row" />
                        <CRUDth th="Column" />
                        <CRUDth th="Type" />
                        <CRUDth th="Actions" />
                    </CRUDTableHeader>
                    <tbody>
                        {seats && seats.length > 0 && seats?.map((seat) => (
                            <CRUDTableRow key={seat._id}>
                                <ShortTextCell text={seat?.seatName ? seat?.seatName : "Not Provide"} />
                                <ShortTextCell text={seat?.seatId} />
                                <ShortTextCell text={seat?.row} />
                                <ShortTextCell text={seat?.column} />
                                <ShortTextCell text={seat?.status} />

                                <td className="action_button_cell">
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={seat._id}
                                        setTargetID={setTargetID}
                                    />
                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>

                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>

            {showModal && (
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showUpdateForm && `Update Seat`)
                    }
                >

                    {showUpdateForm && (
                        <UpdateHallSeat
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    )}
                </Modal>

            )}
        </>
    );
}

export default HallSeats;
