import React, { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { PiStairsThin } from "react-icons/pi";
import { FaWheelchair, FaBan, FaChair, FaCrown, FaChild } from 'react-icons/fa';
import UpdateHallStatusModal from "./UpdateHallStatusModal";

function getIcon(status) {
  switch (status) {
    case "recliner":
      return <FaChair color="darkviolet" size={14} />;
    case "premium":
      return <FaCrown color="goldenrod" size={14} />;
    case "standard":
      return <FaChair color="coral" size={14} />;
    case "kids":
      return <FaChild color="skyblue" size={14} />;
    case "wheelchair":
      return <FaWheelchair color="darkorange" size={14} />;
    case "stair":
      return <PiStairsThin color="darkgray" size={14} />;
    case "unavailable":
      return <FaBan color="crimson" size={14} />;
    default:
      return null;
  }
}

function UpdateSeatStatus({ rows, columns, setSeats, seats, triggerFetch }) {
  const [selectedSeatId, setSelectedSeatId] = useState(null);
  const [isSingleSeatModalOpen, setIsSingleSeatModalOpen] = useState(false);
  const [singleStatus, setSingleStatus] = useState('');
  const axiosInstance = useAxiosInstance();

  function handleSeatClickWrapper(seatId) {
    setSelectedSeatId(seatId === selectedSeatId ? null : seatId);
    setIsSingleSeatModalOpen(true);
  }

  async function handleSingleSeatStatus(status) {
    const response = await axiosInstance.patch(
      "theaters/halls/hallSeats/updateSeat/" + selectedSeatId,
      { status }
    );

    if (response) {
      setIsSingleSeatModalOpen(false);
      setSingleStatus('');
      triggerFetch();
    }
  }

  return (
    <div className="seat-management">
      <UpdateHallStatusModal
        isOpen={isSingleSeatModalOpen}
        onClose={() => setIsSingleSeatModalOpen(false)}
        onSave={handleSingleSeatStatus}
        setSingleStatus={setSingleStatus}
      />


      {seats?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: rows }, (_, rowIndex) => (
              <tr key={rowIndex}>
                {Array.from({ length: columns }, (_, colIndex) => {
                  const seat = seats.find(
                    (seat) => seat.row === rowIndex + 1  && seat.column === colIndex + 1 
                  );
                  return (
                    <td key={colIndex}>
                      <button
                        type="button"
                        className={`square-button ${seat?.status}`}
                        onClick={() => {
                          handleSeatClickWrapper(seat?._id);
                        }}
                        style={{ fontSize: "8px", color: "white" }}
                      >
                        {getIcon(seat?.status)}
                      </button>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default UpdateSeatStatus;
