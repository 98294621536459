import React, { useEffect, useState } from "react";
// import { SOCIAL_LINKS_API } from '../../../Utilities/APIs/APIs';
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewSocialMediaScreen({
  //common props
  targetID,
  employee,
}) {
  const [socialMediaScreen, setSocialMediaScreen] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetSocialMediaScreen() {
      const { data } = await axiosInstance.get(
        "websiteContents/socialLinks/getSingleSocialLink/" + targetID
      );
      setSocialMediaScreen(data);
    }
    fetchAndSetSocialMediaScreen();
  }, [targetID, axiosInstance]);

  // console.log(socialMediaScreen)
  return (
    <div className="crud_view_content">
      {socialMediaScreen && (
        <>
          <h1>Name</h1>
          <p>{socialMediaScreen.name}</p>
          <h1>Visibility</h1>
          <p>{socialMediaScreen.visibility? "True" : "False"}</p>
          <h1>Link</h1>
          <p>{socialMediaScreen.link}</p>
        </>
      )}
    </div>
  );
}

export default ViewSocialMediaScreen;
