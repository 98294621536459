import React from "react";
import "./UpdateHallSeatModal.css";
import SeatIcon from "../Entrances/SeatManagement/SeatIcon";

function UpdateHallStatusModal({ isOpen, onClose, onSave, setSingleStatus }) {
  if (!isOpen) return null;

  const handleStatusChange = (status) => {
    setSingleStatus(status);
    onSave(status);
  };

  const statuses = ["recliner", "premium", "standard", "kids", "wheelchair", "stair", "unavailable"];

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="naming_modal_overlay" onClick={handleOverlayClick}>
      <div className="namingModal-content" onClick={(e) => e.stopPropagation()}>
        {statuses.map((status) => (
          <button
            key={status}
            type="button"
            className={`dropdown-button ${status}`}
            onClick={() => handleStatusChange(status)}
          >
            <span className={`status-icon ${status}`}>
              <SeatIcon status={status} />
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default UpdateHallStatusModal;
