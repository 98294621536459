import React, { useState } from "react";
import "./NamingSeatManagement.css";
import NamingModal from "./NamingModal";
import SingleSeatModal from "./SingleSeatModal/SingleSeatModal";

function NamingSeatManagement({ rows, columns, setSeats, seats, usedLetters, setUsedLetters, shows, view }) {
  
  const [selectedSeatId, setSelectedSeatId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSingleSeatModalOpen, setIsSingleSeatModalOpen] = useState(false);
  const [singleSeatName, setSingleSeatName] = useState('');
  const [reverse, setReverse] = useState(false); // State to handle reversal

  function handleSeatClickWrapper(seatId) {
    setSelectedSeatId(seatId === selectedSeatId ? null : seatId);
    if (view ) {
      setIsSingleSeatModalOpen(false);
    } else {
      setIsSingleSeatModalOpen(true);
    }
  };

  function handleRowButtonClick(rowIndex) {
    const rowSeats = seats.filter(seat => seat.row === rowIndex + 1);
    const rowHasSeatNames = rowSeats.filter(seat => seat.seatName).length > 0;

    if (rowHasSeatNames) {
      setSeats((prevSeats) =>
        prevSeats.map((seat) => {
          if (seat.row === rowIndex + 1) {
            return { ...seat, seatName: '' };
          }
          return seat;
        })
      );
      setUsedLetters((prevUsedLetters) =>
        prevUsedLetters.filter(letter =>
          !rowSeats.filter(seat => seat.seatName).some(seat => seat.seatName.includes(letter))
        )
      );
    } else {
      const allStairOrUnavailable = rowSeats.every(seat => seat.status === "stair" || seat.status === "unavailable");
      if (!allStairOrUnavailable) {
        setSelectedRow(rowIndex);
        setIsModalOpen(true);
      }
    }
  };

  function handleSelectLetter(letter) {
    setIsModalOpen(false);
    setUsedLetters((prev) => [...prev, letter]);

    setSeats((prevSeats) => {
      let seatNumber = reverse ? columns : 1; // Adjust seat numbering based on 'reverse' state
      return prevSeats.map((seat) => {
        if (seat.row === selectedRow + 1) {
          if (["kids", "wheelchair", "standard", "recliner", "premium"].includes(seat.status)) {
            seat.seatName = `${letter}${seatNumber}`;
            seatNumber = seatNumber + 1;
          }
        }
        return seat;
      });
    });
  };

  function handleSingleSeatSave() {
    setSeats((prevSeats) =>
      prevSeats.map((seat) =>
        seat.seatId === selectedSeatId ? { ...seat, seatName: singleSeatName } : seat
      )
    );
    setIsSingleSeatModalOpen(false);
    setSingleSeatName('');
  };


  return (
    <div className="seat-management">
      <NamingModal
        rows={rows}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelectLetter={handleSelectLetter}
        usedLetters={usedLetters}
      />
      <SingleSeatModal
        isOpen={isSingleSeatModalOpen}
        onClose={() => setIsSingleSeatModalOpen(false)}
        onSave={handleSingleSeatSave}
        seatName={singleSeatName}
        setSeatName={setSingleSeatName}
      />
      {seats.length > 0 && (
        <table>
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: rows }, (_, rowIndex) => (
              <tr key={rowIndex}>
                {
                  view ? "" :
                    <td>
                      <div style={{ position: "relative", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                        <button
                          type="button"
                          className="square-button"
                          onClick={() => handleRowButtonClick(rowIndex)}
                          disabled={shows}
                        >
                        </button>
                      </div>
                    </td>
                }

                {Array.from({ length: columns }, (_, colIndex) => {
                  const seat = seats.find(
                    (seat) => seat.row === rowIndex + 1 && seat.column === colIndex + 1
                  );
                  return (
                    <td key={colIndex}>
                      <button
                        type="button"
                        className={`square-button ${seat?.status}`}
                        onClick={() => {
                          handleSeatClickWrapper(seat?.seatId);
                        }}
                        style={{ fontSize: "8px", color: "white" }}
                      >
                        {seat?.seatName}
                      </button>
                    </td>
                  );
                })}

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default NamingSeatManagement;
