import { Link } from "react-router-dom"


function CardLlinkButton({ link, icon, buttonText }) {
    return (
        <Link to={link}>
            <button>
                {buttonText} {icon}
            </button>
        </Link>
    )
}

export default CardLlinkButton