import Tabs from "../Tabs/Tabs"


function CreateHallButton({ activeTab, handlePrev, handleNext, handleSubmit, rows, columns, name, isSubmitDisabled }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Tabs hall activeTab={activeTab} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {
          activeTab === 3 && 
          <button
            type="button"
            onClick={handlePrev}
            style={{
              border: "1px solid lightgray",
              color: "white",
              textAlign: "center",
              width: "200px",
              padding: "10px",
              background: "var(--gray-8)",
              borderRadius: "10px",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Back
          </button>
        }

        {
          activeTab < 3 &&
          <button
            style={{
              textAlign: "center",
              width: "200px",
              padding: "10px",
              background: "var(--primary-color)",
              borderRadius: "10px",
              fontSize: "18px",
              fontWeight: "600",
            }}
            type="button"
            // onClick={() => (activeTab === 3 ? handleSubmit() : handleNext())}
            onClick={handleNext}
            disabled={!rows || !columns || !name}
          >
            Next
          </button>
        }

        {
          activeTab === 3 &&
          <button
            style={{
              textAlign: "center",
              width: "200px",
              padding: "10px",
              background: "var(--primary-color)",
              borderRadius: "10px",
              fontSize: "18px",
              fontWeight: "600",
            }}
            type="submit"
            // onClick={() => (activeTab === 3 ? handleSubmit() : handleNext())}
            onClick={handleSubmit}
            disabled={!rows || !columns || !name || isSubmitDisabled}
          >
            Confirm
          </button>
        }

      </div>
    </div>
  )
}


export default CreateHallButton


