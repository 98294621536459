import React from 'react';
import DropdownMenu from './DropdownMenu';
import SeatButton from './SeatButton';
import SeatIcon from './SeatIcon';

function TableBody({ rows, columns, setSeats, setColumnTypes, setRowTypes, seats, rowTypes, activeDropdown, handleRowSelect, handleButtonStatusClick, handleSeatClick, selectedSeatId, view, handleSeatTypeChange,setActiveDropdown }) {
    return (
        <tbody>
            {Array.from({ length: rows }, (_, rowIndex) => (

                <tr key={rowIndex}>
                    {
                        view ? "" : <td>
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px"
                                }}>

                                <button
                                    type="button"
                                    className="square-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleButtonStatusClick(
                                            "row",
                                            rowIndex,
                                            activeDropdown,
                                            setActiveDropdown,
                                            rowTypes,
                                            setRowTypes,
                                            setSeats
                                        );
                                    }}
                                >
                                    {rowTypes[rowIndex] && (
                                        <span
                                            className={`status-icon ${rowTypes[rowIndex]}`}>
                                            <SeatIcon
                                                status={rowTypes[rowIndex]}
                                            />
                                        </span>
                                    )}
                                </button>


                                {activeDropdown.type === "row" &&
                                    activeDropdown.index === rowIndex && (
                                        <DropdownMenu
                                            type="row"
                                            index={rowIndex}
                                            handleSelect={handleRowSelect}
                                        />
                                    )}

                                <div
                                    style={{ fontWeight: "600" }}
                                    className="header-label"
                                >
                                    {rowIndex + 1}
                                </div>
                            </div>
                        </td>
                    }

                    {Array.from({ length: columns }, (_, colIndex) => {
                        const seat = seats.find(seat => seat.row === rowIndex + 1 && seat.column === colIndex + 1);
                        return (
                            <td key={colIndex}>
                                <SeatButton
                                    seat={seat}
                                    setSeats={setSeats}
                                    setRowTypes={setRowTypes}
                                    setColumnTypes={setColumnTypes}
                                    handleSeatClick={handleSeatClick}
                                    selectedSeatId={selectedSeatId}
                                    view={view}
                                    handleSeatTypeChange={handleSeatTypeChange}
                                />
                            </td>
                        );
                    })}

                </tr>
            ))}
        </tbody>
    );
}

export default TableBody;
