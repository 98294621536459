import { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import CreateHallButton from "./CreateHallButton/CreateHallButton";
import NamingSeatManagement from "./NamingSeatMangement/NamingSeatManagement";
import SeatManagement from "./SeatManagement/SeatManagement";


function CreateHall({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  theaterId,
}) {
  const [seats, setSeats] = useState([]);
  const [name, setName] = useState("");
  const [rows, setRows] = useState("");
  const [columns, setColumns] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [usedLetters, setUsedLetters] = useState([]);

  const axiosInstance = useAxiosInstance();

  const handleNext = () => {
   if(activeTab === 1) {
    setActiveTab(2)
   } else if( activeTab === 2) {
    setActiveTab(3)
   }
  };
  const handlePrev = () => {
    setActiveTab((prev) => {
      return prev > 1 ? prev - 1 : prev;
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if(seats.length < 0) {
      return;
    }
    const itemData = {
      name,
      theater: theaterId,
      numberOfRows: rows,
      numberOfColumns: columns,
      seats,
    };

    const response = await axiosInstance.post(
      "theaters/halls/createHallByTheaterId/",
      itemData
    );
    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  const containerStyle = {
    height: "75vh",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  };

  const isSubmitDisabled = seats && seats.length > 0 && seats?.some(seat => 
    (seat.status !== 'stair' && seat.status !== 'unavailable') && !seat.seatName
  );

  console.log("seats are",seats)

  return (
    <>
      <Form  onSubmit={handleSubmit} hasImage>
        <div>
          <div className="scroll-container" style={containerStyle}>
            {activeTab === 1 && (
              <>
                <ShortTextInput
                  label={`Hall Name`}
                  value={name}
                  placeholder={`Enter Hall Name`}
                  setState={setName}
                />
                <NumberInput
                  label={`Number of rows`}
                  value={rows}
                  placeholder={`Enter Number Of Rows`}
                  setState={setRows}
                />
                <NumberInput
                  label={`Number Of Columns`}
                  value={columns}
                  placeholder={`Enter Number Of Columns`}
                  setState={setColumns}
                />
              </>
            )}

            {activeTab === 2 && (
              <>
                <SeatManagement
                  rows={rows}
                  columns={columns}
                  seats={seats}
                  setSeats={setSeats}
                />
              </>
            )}

            {
              activeTab === 3 && (
                <>
                <NamingSeatManagement
                rows={rows}
                columns={columns}
                seats={seats}
                setSeats={setSeats}
                usedLetters={usedLetters}
                setUsedLetters={setUsedLetters}
                />
                </>
              )
            }
          </div>
        </div>

        <div>
          <CreateHallButton
            rows={rows}
            columns={columns}
            name={name}
            activeTab={activeTab}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
          />
        </div>
      </Form>
    </>
  );
}

export default CreateHall;
