import SmallSideBarItem from "./SmallSideBarItem/SmallSideBarItem";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";

import {
  RiCalendarEventLine,
  RiDirectionLine,
  RiGroupLine,
  RiHome2Line,
  RiMoneyDollarBoxLine,
  RiOrderPlayLine,
  RiSearch2Line,
  RiSettings2Line,
  RiStackLine,
  RiVipCrownLine,
} from "react-icons/ri";

import "./SideBar.css";

function SideBar() {
  return (
    <>
      <section className="small_sidebar">
        <div className="container">
          <SmallSideBarItem link="/" dataTip="Home Page" dataFor="dashboard">
            <RiHome2Line />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/eventsStatusScreen"
            dataTip="Events"
            dataFor="Events"
          >
            <RiCalendarEventLine />
          </SmallSideBarItem>

          <SmallSideBarItem link="/theaters" dataTip="Theaters" dataFor="Theaters">
            <RiStackLine />
          </SmallSideBarItem>



          <SmallSideBarItem link="/promocodes" dataTip="Promocode" dataFor="Promocode">
            <RiMoneyDollarBoxLine />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/usersScreen"
            dataTip="Users"
            dataFor="Users">
            <RiGroupLine />
          </SmallSideBarItem>


          <SmallSideBarItem
            link="/appSettings"
            dataTip="App Settings"
            dataFor="App Settings"
          >
            <RiSettings2Line />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/toofanAtHoytsInAustralia"
            dataTip="Toofan At Hoyts"
            dataFor="Toofan At Hoyts"
          >
            <RiVipCrownLine />

          </SmallSideBarItem>

          <SmallSideBarItem
            link="/searchOrder"
            dataTip="Search Order"
            dataFor="Search Order"
          >
            <RiSearch2Line />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/duplicateOrder"
            dataTip="Duplicate Order"
            dataFor="Duplicate Order"
          >
            <HiOutlineDocumentDuplicate />

          </SmallSideBarItem>


        </div>
      </section>
    </>
  );
}

export default SideBar;
