

function OrderCard({ icon, heading, total }) {
    return (
        <div className="details_card_container">
            <div className="card_icon">
                {icon}
            </div>

            <div>
                <p>{heading}</p>
                <p className="total">{total}</p>
            </div>

        </div>
    )
}

export default OrderCard