import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Pagination.css';

function Pagination({ totalPages, currentPage, onPageChange }) {
    const maxPagesToShow = 10;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage, endPage;
    if (totalPages <= maxPagesToShow) {
        // Total pages less than max pages to show
        startPage = 1;
        endPage = totalPages;
    } else {
        // Total pages more than max pages to show
        if (currentPage <= halfMaxPagesToShow) {
            startPage = 1;
            endPage = maxPagesToShow;
        } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
        }
    }

    const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
        <div className="pagination">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                className="pagination-button"
                disabled={currentPage === 1}
            >
                <FaChevronLeft />
            </button>
            {startPage > 1 && (
                <>
                    <button
                        onClick={() => onPageChange(1)}
                        className="pagination-button"
                    >
                        1
                    </button>
                    {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                </>
            )}
            {pages.map((page) => (
                <button
                    key={page}
                    onClick={() => onPageChange(page)}
                    className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                >
                    {page}
                </button>
            ))}
            {endPage < totalPages && (
                <>
                    {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                    <button
                        onClick={() => onPageChange(totalPages)}
                        className="pagination-button"
                    >
                        {totalPages}
                    </button>
                </>
            )}
            <button
                onClick={() => onPageChange(currentPage + 1)}
                className="pagination-button"
                disabled={currentPage === totalPages}
            >
                <FaChevronRight />
            </button>
        </div>
    );
}

export default Pagination;
