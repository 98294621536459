import InputBoilerplate from "../InputBoilerplate";

function TimeInput ({label, value, placeholder, setState}) {
    return (
        <InputBoilerplate
        label={label}
        type={'time'}
        value={value}
        placeholder={placeholder}
        setState={setState}  
    />
    );
};

export default TimeInput;