import { useEffect, useState } from 'react';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';

import './archiveOrder.css';
import { ORDERS_API } from '../../../../Utilities/APIs/APIs';

function ArchiveOrder({
  targetID,
  setShowDeleteSection,
  setShowModal,
  triggerFetch,
}) {
  const [order, setOrder] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetOrder() {
      const { data } = await axiosInstance.get(
        ORDERS_API + 'getSingleOrder/' + targetID,
      );
      setOrder(data);
    }
    fetchAndSetOrder();
  }, [targetID, axiosInstance]);

  console.log(targetID);

  console.log('order', order);
  async function handleDelete() {
    const response = await axiosInstance.patch(
      ORDERS_API + 'archiveOrder/' + targetID,
    );
    console.log('response', response);
    if (response) {
      setShowDeleteSection(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <div className='modal_delete_content'>
      <p>Are you sure you want to Archive this order?</p>
      <div className='order_details'>
        {order && (
          <>
            <p>
              <span>Transaction:</span>
              {order.transactionId}
            </p>
            <p>
              <span>Show:</span>
              {`${order?.show?.theater?.name}--${
                order?.show?.hall?.name
              }--${new Date(order?.show?.date).toLocaleDateString()}`}
            </p>
            <p>
              <span>Event:</span> {order?.event.name}
            </p>
            <p>
              <span>Customer:</span> {order?.name}
            </p>
            <p>
              <span>Email:</span> {order?.email}
            </p>
            <p>
              <span>Phone: </span>
              {order?.mobileNumber}
            </p>
            <p>
              <span>Total:</span> {order?.total}
            </p>
          </>
        )}
      </div>
      <div className='modal_group_buttons'>
        <button
          className='delete'
          onClick={() => {
            handleDelete();
          }}
        >
          Archive
        </button>

        <button
          className='cancel'
          onClick={() => {
            setShowModal(false);
            setShowDeleteSection(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ArchiveOrder;
