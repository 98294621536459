import React, {  useEffect, useState } from "react";
// import slugify from "react-slugify";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import DateInput from "../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import slugify from "react-slugify";


function UpdateEvent({ setShowUpdateForm, setShowModal, triggerFetch,targetID }) {
  const axiosInstance = useAxiosInstance()
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [duration, setDuration] = useState("");
  const [type, setType] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [releaseType,setReleaseType] = useState("")
  const [theatricalLink, setTheatricalLink] = useState("") 
  const [trailerVideoLink, setTrailerVideoLink] = useState("") 



  useEffect(() => {
    
    async function fetchAndSetEvent() {
        const { data } = await axiosInstance.get('events/getSingleEvent/' + targetID)
        setName(data.name)
        setReleaseType(data.releaseType)
        setTheatricalLink(data?.theatricalLink)
        setTrailerVideoLink(data?.trailerVideoLink)
        setDescription(data.description)
        setLocation(data.location)
        const formattedReleaseDate = data.releaseDate.split('T')[0]
        setReleaseDate(formattedReleaseDate)
        setOrganizer(data.organizer)
        setDuration(data.duration)
        setType(data.type)
        setCardImage(data.cardImage)
        setBannerImage(data.bannerImage)  
    }
    fetchAndSetEvent()

}, [targetID, axiosInstance])

  async function handleSubmit(e) {
    e.preventDefault();
    const formattedReleaseDate = releaseDate.split('T')[0];

    const itemData = new FormData();
   
    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("releaseType", releaseType);
    itemData.append("theatricalLink", theatricalLink);
    itemData.append("trailerVideoLink", trailerVideoLink);
    itemData.append("description", description);
    itemData.append("location", location);
    itemData.append("organizer", organizer);
    itemData.append("releaseDate", formattedReleaseDate);
    itemData.append("type", type);
    itemData.append("duration", duration);
    itemData.append("cardImage", cardImage);
    itemData.append("bannerImage", bannerImage);

    const response = await axiosInstance.patch("events/" + targetID, itemData)
    if (response.data) {
        setShowUpdateForm(false);
        setShowModal(false);
        triggerFetch()
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Event Name`}
        setState={setName}
      />
       <SelectInput label={"Type"} value={type} setState={setType}>
        <SelectOption optionValue="" optionText="Select Type" />
        <SelectOption optionValue="Movie" optionText="Movie" />
        <SelectOption optionValue="Others" optionText="Others" />
      </SelectInput>

      <SelectInput label={"Release Type"} value={releaseType} setState={setReleaseType}>
        <SelectOption optionValue="" optionText="Select Release Type" />
        <SelectOption optionValue="private screen" optionText="Private Screen" />
        <SelectOption optionValue="theatrical" optionText="Theatrical Screen" />
      </SelectInput>

      {releaseType === "theatrical" &&
        <ShortTextInput
          label={`Theatrical Page Link`}
          value={theatricalLink}
          placeholder={`Theatrical Page Link`}
          setState={setTheatricalLink}
        />
      }

      <ShortTextInput
        label={`Location`}
        value={location}
        placeholder={`Event Location`}
        setState={setLocation}
      />

      <ShortTextInput
        label={`Organizer`}
        value={organizer}
        placeholder={`Event Organizer`}
        setState={setOrganizer}
      />

      <DateInput
        label={`Release Date`}
        value={releaseDate}
        placeholder={`Release Date`}
        setState={setReleaseDate}
      />

      <ShortTextInput
        label={`Duration`}
        value={duration}
        placeholder={`Enter Duration`}
        setState={setDuration}
      />

      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />

      <ShortTextInput
        label={`Trailer Video link`}
        value={trailerVideoLink}
        placeholder={`Trailer video link`}
        setState={setTrailerVideoLink}
      />
      <ImageInput
        fieldId="1"
        state={cardImage}
        setState={setCardImage}
        allowUpdateImage
      >
        Upload Card Image
      </ImageInput>
      <ImageInput
        fieldId="2"
        state={bannerImage}
        setState={setBannerImage}
        allowUpdateImage
        banner
      >
        Upload Banner Image
      </ImageInput>
      <FormSubmitButton text="Update Event" />
    </Form>
  );
}

export default UpdateEvent;
