import ContentWrapper from "../ContentWrapper/ContentWrapper"
import TopHeading from "../TopHeading/TopHeading"
import OrderDetailsWrapper from "./OrderDeatilsWrapper/OrderDetailsWrapper"



function OrderAmountDetails({ totalSoldTickets, totalOrder,yesterdaySoldTickets, todaySoldTickets, last24hrSoldTickets}) {
    return (
        <div>

            <TopHeading heading={"Order Details"} />
            <ContentWrapper>
                <OrderDetailsWrapper
                    totalOrder={totalOrder}
                    totalSoldTickets={totalSoldTickets}
                    yesterdaySoldTickets={yesterdaySoldTickets}
                    todaySoldTickets={todaySoldTickets}
                    last24hrSoldTickets={last24hrSoldTickets}
                />

            </ContentWrapper>
        </div>
    )
}

export default OrderAmountDetails