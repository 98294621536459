

import { useEffect, useState } from 'react';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import { ORDERS_API } from '../../../../Utilities/APIs/APIs';
import OrderInfo from '../../../Partials/Elements/OrderInfo/OrderInfo';


function ViewDuplicateOrders({ targetID }) {

  const [orders, setOrders] = useState(null);
  const axiosInstance = useAxiosInstance()

  useEffect(() => {
    async function searchOrder() {
      const response = await axiosInstance.post("orders/ordersByconflictIds", {
        mainorderId: targetID.mainOrderId,
        conflictOrderId: targetID.conflictOrderId,
      })

      if (response) {
        setOrders(response.data);
      }
    }
    searchOrder()
  }, [targetID])


  const { mainorder, mainOrderItemseats, conflictOrder, conflictOrderItemseats } = orders || {};

  return (
    <div className='crud_view_content'>
      {orders && (
        <>
          <h2>Main Order</h2>
          <OrderInfo order={mainorder} seats={mainOrderItemseats} />

          <div style={{ marginTop: "20px", border: "1px solid", width:"100%" }}></div>
          <h2>Conflict Order</h2>
          <OrderInfo order={conflictOrder} seats={conflictOrderItemseats} />
        </>
      )}
    </div>
  );
}

export default ViewDuplicateOrders;
