import { RiGroup2Line, RiGroupLine } from "react-icons/ri"
import BackButton from "../../Partials/Elements/BackButton/BackButton"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance"


function EventScreen() {

    const { eventId } = useParams()

    const [eventName, setEventName] = useState("")
    const axiosInstance = useAxiosInstance()

    useEffect(() => {
        async function fetchAndSetEventInfo() {
            const { data } = await axiosInstance.get('events/getSingleEvent/' + eventId);
            setEventName(data.name);
        }
        fetchAndSetEventInfo();
    }, [eventId, axiosInstance]);
    return (

        <div style={{ display: "flex", flexDirection: "column", paddingTop: "40px", gap: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <BackButton />
                <h1 className="heading">Event - {  eventName}</h1>
            </div>
            
            <NavCardList numOfCards={'four'}>
                <NavCard cardName={'Shows'} navCardLink={`/events/shows/${eventId}`} ><RiGroupLine /></NavCard>
                <NavCard cardName={'Crews'} navCardLink={`/events/crews/${eventId}`} ><RiGroup2Line /></NavCard>
                {/* <NavCard cardName={'Event Prices'} navCardLink={`/events/prices/${eventId}`} ><RiGroup2Line /></NavCard> */}
            </NavCardList>
        </div>


    )
}

export default EventScreen