function ViewSelect({ filter, setFilter }) {

    return (

        <div style={{display:"flex",alignItems:"center"}}>

         <select className="filter_select" value={filter} onChange={(e)=>setFilter(e.target.value)} >
            <option value={"details"}>Details</option>
            <option value={"stripe"}>Stripe</option>
        </select>   
        </div>
        
    )
}

export default ViewSelect