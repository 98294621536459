

import React, {  useEffect, useState } from "react";

import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";



function UpdateStatus({ setShowStatusSection, setShowModal, triggerFetch,targetID }) {
  const axiosInstance = useAxiosInstance()

  const [status, setStatus] = useState("");
  

  useEffect(() => {
    
    async function fetchAndSetEvent() {
        const { data } = await axiosInstance.get('events/getSingleEvent/' + targetID)
        setStatus(data?.status)   
    }
    fetchAndSetEvent()

}, [targetID, axiosInstance])


  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
   
    itemData.append("status", status);
  
    const response = await axiosInstance.patch("events/updateEventStatus/" + targetID, itemData)
    if (response.data) {
      setShowStatusSection(false);
        setShowModal(false);
        triggerFetch()
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
     
       <SelectInput label={"Select Status"} value={status} setState={setStatus}>
        <SelectOption optionValue="" optionText="Select Status" />
        <SelectOption optionValue="nowSelling" optionText="Now Selling" />
        <SelectOption optionValue="upcoming" optionText="Upcoming" />
        <SelectOption optionValue="past" optionText="Past" />
        <SelectOption optionValue="voteToBring" optionText="Vote To Bring" />
      </SelectInput>

    
      <FormSubmitButton text="Update Event" />
    </Form>
  );
}

export default UpdateStatus;
