import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import AppContext from "../Context/AppContext";
import Login from "./Screens/AuthScreens/Login/Login";
import Register from "./Screens/AuthScreens/Register/Register";
import RecoverPassOne from "./Screens/AuthScreens/RecoverPassOne";
import RecoverPassThree from "./Screens/AuthScreens/RecoverPassThree";
import EmployeesScreen from "./Screens/AuthScreens/EmployeesScreen";
import InviteEmployees from "./Screens/AuthScreens/InviteEmployees/InviteEmployees";
import Employees from "./Screens/AuthScreens/Employees/Employees";
import Customers from "./Screens/UsersScreen/Customers/Customers";
import NavBar from "./Partials/Sections/NavBar/NavBar";
import Loader from "./Partials/Elements/Loader/Loader";
import Dashboard from "./Screens/Dashboard/Dashboard";
import SideBar from "./Partials/Sections/SideBar/SideBar";
import UsersScreen from "./Screens/UsersScreen/UsersScreen";
import EmployeeScreen from "./Screens/AuthScreens/Employees/EmployeeScreen";
import Events from "./Screens/Events/Events";
import SocialMediaScreen from "./Screens/AppSettingsScreens/SocialMediaScreen/SocialMediaScreen";
import EventScreen from "./Screens/Events/EventScreens";
import Crews from "./Screens/Events/Crews/Crews";
import Shows from "./Screens/Events/Shows/Shows";
import HeroSliders from "./Screens/AppSettingsScreens/HeroSliders/HeroSliders";
import AppSettingsScreens from "./Screens/AppSettingsScreens/AppSettingsScreens";
import EventStatusScreens from "./Screens/Events/EventStatusScreens";
import Ads from "./Screens/AppSettingsScreens/Ads/Ads";
import Theaters from "./Screens/Theaters/Theaters";
import Entrances from "./Screens/Theaters/Entrances/Halls";
import HallSeats from "./Screens/Theaters/Entrances/HallSeats/HallSeats";
import TicketCategories from "./Screens/TicketCategories/TicketCategories";
import FeesScreen from "./Screens/Fees/Fees";
import PromoCode from "./Screens/Fees/PromoCode/PromoCode";
import ShowsPrice from "./Screens/Events/ShowsPrice/ShowsPrice";
import States from "./Screens/States/States";
import City from "./Screens/States/City/City";
import Country from "./Screens/States/Country/Country";
import Orders from "./Screens/Orders/Order";
import OrderItems from "./Screens/Orders/OrderItems/OrderItems";
import UpvoteEventUser from "./Screens/Events/UpvoteEventUser/UpvoteEventUser";
import SearchOrder from "./Screens/SearchOrder/SearchOrder";
import DuplicateOrders from "./Screens/DuplicateOrders/DuplicateOrders";
import ToofanAtHoytsInAustralia from "./Screens/ToofanAtHoytsInAustralia/ToofanAtHoytsInAustralia";


function App() {
  const { employee, isLoading } = useContext(AppContext);

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className="screen-container">
            <Routes>
              <Route
                path="/login"
                element={!employee ? <Login /> : <Navigate to={"/"} />}
              />
              <Route
                path="/register/:token"
                element={!employee ? <Register /> : <Navigate to={"/"} />}
              />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route
                path="/resetEmployeePassword/:token"
                element={<RecoverPassThree />}
              />

              <Route
                path="/employeesScreen"
                element={
                  employee ? <EmployeesScreen /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/employeeScreen"
                element={
                  employee ? <EmployeeScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/usersScreen"
                element={
                  employee ? <UsersScreen /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/employeeInvites"
                element={
                  employee ? <InviteEmployees /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/employees"
                element={employee ? <Employees /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/customers"
                element={employee ? <Customers /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/"
                element={employee ? <Dashboard /> : <Navigate to={"/login"} />}
              />

              {/* events */}
              <Route
                path="/eventsStatusScreen"
                element={
                  employee ? <EventStatusScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/events/:status"
                element={employee ? <Events /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/eventsScreens/:eventId"
                element={
                  employee ? <EventScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/events/crews/:eventId"
                element={employee ? <Crews /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/events/shows/:eventId"
                element={employee ? <Shows /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/shows/prices/:showsId"
                element={employee ? <ShowsPrice /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/event/upvoted/user/:eventName/:eventId"
                element={employee ? <UpvoteEventUser /> : <Navigate to={"/login"} />}
              />

              {/* appp setting */}
              <Route
                path="/appSettings"
                element={
                  employee ? <AppSettingsScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/socialLinks"
                element={
                  employee ? <SocialMediaScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/heroSliders"
                element={
                  employee ? <HeroSliders /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/ads"
                element={employee ? <Ads /> : <Navigate to={"/login"} />}
              />

              {/* theater */}
              <Route
                path="/theaters"
                element={employee ? <Theaters /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/entrances/:theaterId"
                element={employee ? <Entrances /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/halls/seatManagement/:hallId"
                element={employee ? <HallSeats /> : <Navigate to={"/login"} />}
              />

              {/* Ticket Categories */}

              <Route
                path="/ticket/categories"
                element={
                  employee ? <TicketCategories /> : <Navigate to={"/login"} />
                }
              />

              {/* fees */}

              <Route
                path="/fees"
                element={employee ? <FeesScreen /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/promocodes"
                element={employee ? <PromoCode /> : <Navigate to={"/login"} />}
              />

              {/* Order */}
              {/* <Route
                path="/orders"
                element={employee ? <PromoCode /> : <Navigate to={"/login"} />}
              /> */}


              {/* state and citis */}
              <Route
                path="/countries"
                element={employee ? <Country /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/country/states/:countryId"
                element={employee ? <States /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/state/city/:stateId"
                element={employee ? <City /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/shows/orders/:showId"
                element={employee ? <Orders /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/orderItems/:orderId"
                element={employee ? <OrderItems /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/searchOrder"
                element={employee ? <SearchOrder /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/duplicateOrder"
                element={employee ? <DuplicateOrders /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/toofanAtHoytsInAustralia"
                element={employee ? <ToofanAtHoytsInAustralia /> : <Navigate to={"/login"} />}
              />


            </Routes>



          </section>
        </main>
      </BrowserRouter>
      <ToastContainer />
      {isLoading && <Loader />}
    </section>
  );
}

export default App;
