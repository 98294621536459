import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";


function CreateTheater({ setShowCreateForm, setShowModal, triggerFetch }) {

  const [name, setName] = useState("");
  const [address, setaddress] = useState("");

  const [cities, setCities] = useState([])
  const [city, setCity] = useState("")

  const [country, setCountry] = useState()
  const [countries, setCountries] = useState([])

  const [states, setStates] = useState([])
  const [state, setState] = useState("")

  const [zipCode, setzipCode] = useState("");

  const axiosInstance = useAxiosInstance();



  useEffect(() => {

    async function fetchAndSetCountries() {
      const { data } = await axiosInstance.get("/countries")
      setCountries(data)
    }
    fetchAndSetCountries()

  }, [axiosInstance])

  useEffect(() => {
    
    async function fetchAndSetStates() {
      const { data } = await axiosInstance.get("/states/getAllStateByCountryId/" + country)
      setStates(data)
    }

    if(country){
      fetchAndSetStates()
    }

  }, [axiosInstance, country])

  useEffect(() => {

    async function fetchAndSetCities() {
      const { data } = await axiosInstance.get("/states/cities/allCitiesByStateId/" + state)
      setCities(data)
    }
    if (state) {
      fetchAndSetCities()
    }

  }, [axiosInstance, state])


  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("address", address);
    itemData.append("city", city);
    itemData.append("state", state);
    itemData.append("zipCode", zipCode);
    itemData.append("country",country)

    const response = await axiosInstance.post("theaters", itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (


  <div style={{height: "70vh"}}>
      <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Theater Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Address`}
        value={address}
        placeholder={`Enter Address`}
        setState={setaddress}
      />


      <SelectInput value={country} setState={setCountry} label={"Select Country"}>
        <SelectOption optionText={"Select Country"} optionValue={""} />

        {
          countries && countries.length > 0 && countries?.map(country =>
            <SelectOption key={country._id} optionText={country?.name} optionValue={country?._id} />
          )
        }
      </SelectInput>


      <SelectInput value={state} setState={setState} label={"Select State"}>
        <SelectOption optionText={"Select State"} optionValue={""} />

        {
          states && states.length > 0 && states?.map(state =>
            <SelectOption key={state._id} optionText={state?.name} optionValue={state?._id} />
          )
        }
      </SelectInput>


      <SelectInput value={city} setState={setCity} label={"Select City"}>
        <SelectOption optionText={"Select City"} optionValue={""} />

        {
          cities && cities.length > 0 && cities?.map(city =>
            <SelectOption key={city._id} optionText={city?.name} optionValue={city?._id} />
          )
        }
      </SelectInput>
     
      <ShortTextInput
        label={`Zip Code`}
        value={zipCode}
        placeholder={`Enter Zip Code`}
        setState={setzipCode}
      />

      <div style={{height:"10vh"}}>
      <FormSubmitButton text="Create Theater" />
      </div>
    </Form>
  </div>
  );
}

export default CreateTheater;
