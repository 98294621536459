import { useContext, useEffect, useState } from "react";
import CreatePromocode from "./CreatePromocode";
import AppContext from "../../../../Context/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import UpdatePromocode from "./UpdatePromocode";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";



function PromoCode() {


  const [promocodes, setPromocodes] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [active, setIsActive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();


  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(`promocode?filter=${filter}`);
      setPromocodes(data);
    }
    fetchAndSetHeroSliders();
  }, [toggleFetch,filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Promocodes (${promocodes?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Promocode"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Code" />
            <CRUDth th="Max Limit" />
            <CRUDth th="Discount Type" />
            <CRUDth th="Discount Amount" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {promocodes?.map((promo) => (
              <CRUDTableRow key={promo._id}>
                <ShortTextCell text={promo?.promoCode} />
                <ShortTextCell text={promo?.maxlimit} />
                <ShortTextCell text={promo?.discountType} />
                <ShortTextCell text={promo?.discountAmount} />
    
                <td className="action_button_cell">
                 
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={promo._id}
                    setTargetID={setTargetID}
                  />

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={promo._id}
                    isActive={promo.isActive}
                    setTargetID={setTargetID}
                    setActive={setIsActive}
                  />
                
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create PromoCode`) ||
            (showUpdateForm && `Update PromoCode`) ||
            (showDeleteSection && `Archive PromoCode`)
          }
        >
          {showCreateForm && (
            <CreatePromocode
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showUpdateForm && (
            <UpdatePromocode
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={"promoCode/archivePromocode/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isActive={active}
            />
          )}
        </Modal>
      )}

    </>
  );
}

export default PromoCode;
