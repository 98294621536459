import { useState } from "react";

import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "./../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateCrew({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  eventId,
}) {
  const axiosInstance = useAxiosInstance();
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("type", type);
    itemData.append("event", eventId);

    const response = await axiosInstance.post(
      "events/crews/getAllCrewsByEventId/" + eventId,
      itemData
    );
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Type"} value={type} setState={setType}>
        <SelectOption optionValue="" optionText="Select Type" />
        <SelectOption optionValue="Director" optionText="Director" />
        <SelectOption optionValue="Producer" optionText="Producer" />
        <SelectOption optionValue="Cast" optionText="Cast" />
        <SelectOption optionValue="localDistributor" optionText="Local Distributor" />
        <SelectOption optionValue="Rating" optionText="Rating" />
      </SelectInput>

      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Crew Name`}
        setState={setName}
      />

      <FormSubmitButton text="Create Crew" />
    </Form>
  );
}

export default CreateCrew;
