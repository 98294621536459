import { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ArchiveButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiImage } from "react-icons/fi";
import Modal from "../../Partials/Elements/Modal/Modal";
import CreateState from "./CreateState";
import UpdateState from "./UpdateState";
import ArchiveItem from "../../Partials/Layouts/ArchiveItem/ArchiveItem";



function States() {

  const {countryId} = useParams()
  const [states, setStates] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(`states/getAllStateByCountryId/${countryId}?filter=${filter}`);
      setStates(data)
    }
    fetchAndSetHeroSliders();
  }, [toggleFetch, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`States (${states && states?.length> 0 ? states?.length : 0} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="State"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {states && states?.length> 0  && states?.map((state) => (
              <CRUDTableRow key={state._id}>
                <ShortTextCell text={state?.name} />
                <td className="action_button_cell">
                  {/* <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={state._id}
                    setTargetID={setTargetID}
                  /> */}
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={state._id}
                    setTargetID={setTargetID}
                  />

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={state._id}
                    isArchive={state?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                  <CRUDButton
                    dataTip=" City"
                    dataFor=" Cities"
                    handleClick={() => navigate("/state/city/" + state._id)}
                  >
                    <FiImage />
                  </CRUDButton>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create State`) ||
            (showUpdateForm && `Update State`) ||
            (showDeleteSection && `Archive State`)
          }
        >
          {showCreateForm && (
            <CreateState
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              countryId={countryId}
            />
          )}
          {showUpdateForm && (
            <UpdateState
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              countryId={countryId}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={"states/archiveState/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

     
    </>
  );
}

export default States;
