


import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";


function UpdateTicketCategories({
  targetID,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [name, setName] = useState("");
  const [color, setColor] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetAds() {
      const { data } = await axiosInstance.get(
        "ticket/categories/getSingleCategory/" + targetID
      );
      setName(data.name);
      setColor(data.color.replace('#', '')); 
    }
    fetchAndSetAds();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("color", `#${color}`);

    const response = await axiosInstance.patch(
      "ticket/categories/" + targetID,
      itemData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} >
      <ShortTextInput
        label={`Category Name`}
        value={name}
        placeholder={`Enter Category Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Color Code`}
        value={color}
        placeholder={`Enter Color Code`}
        setState={setColor}
      />

      <FormSubmitButton text="Update Category" />
    </Form>
  );
}

export default UpdateTicketCategories;
