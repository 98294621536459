import { Link } from "react-router-dom"
import { IMAGE_URL } from "../../../../../Utilities/APIs/APIs"
import { FiEdit, FiEye } from "react-icons/fi"
import CardLink from "./CardLilnk/CardLink"



function EventCard({ event }) {
    
    const eventId = event && event?._id
    return (
        <div className="card_container">
            <div className="event_name_image">
                <img src={IMAGE_URL + event?.cardImage} className="event_image" />
                <p className="event_name">{event?.name}</p>
            </div>

            <CardLink eventId={eventId}/>

        </div>
    )
}

export default EventCard