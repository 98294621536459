


import React, { useEffect, useState } from "react";
import UpdateHallStatusModal from "../../../Theaters/UpdateHallSeat/UpdateHallStatusModal";
import { PiStairsThin } from "react-icons/pi";
import { FaWheelchair, FaBan, FaChair, FaCrown, FaChild, FaRegClock, FaRegBookmark } from 'react-icons/fa';
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import SeatIcon from "../../../Theaters/Entrances/SeatManagement/SeatIcon";
import Tooltip from "./Tooltip/ToolTip";
import { handleGetTooltipContent } from "../../../../../Utilities/HelperFunction/getSeatTooltip";


function UpdateShowseat({ targetID, triggerFetch, toggleFetch }) {

  const [seats, setSeats] = useState([]);
  const [rows, setRows] = useState("");
  const [columns, setColumns] = useState("");

  const [selectedSeatId, setSelectedSeatId] = useState(null);
  const [isSingleSeatModalOpen, setIsSingleSeatModalOpen] = useState(false);
  const [singleStatus, setSingleStatus] = useState('');
  const axiosInstance = useAxiosInstance();

  useEffect(() => {

    async function fetchAndSetHallSeats() {
      try {
        const { data } = await axiosInstance.get("events/shows/getSingleShow/" + targetID);
        setRows(data?.show?.hall?.numberOfRows)
        setColumns(data?.show?.hall?.numberOfColumns)
        setSeats(data?.showsSeats);
      } catch (error) {
        console.error("Error fetching halls:", error);
      }
    }
    fetchAndSetHallSeats()

  }, [axiosInstance, targetID, toggleFetch]);

  function handleSeatClickWrapper(seatId) {
    console.log("clicked")
    setSelectedSeatId(seatId === selectedSeatId ? null : seatId);
    setIsSingleSeatModalOpen(true);
  }


  async function handleSingleSeatStatus(status) {
    const response = await axiosInstance.patch(
      "events/shows/showsSeats/updateShowsSeat/" + selectedSeatId,
      { status }
    );

    if (response) {
      setIsSingleSeatModalOpen(false);
      setSingleStatus('');
      triggerFetch();
    }
  }


  const containerStyle = {
    height: "75vh",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  };


  return (

    <div className="scroll-container" style={containerStyle}>

      <div className="seat-management">

        <UpdateHallStatusModal
          isOpen={isSingleSeatModalOpen}
          onClose={() => setIsSingleSeatModalOpen(false)}
          onSave={handleSingleSeatStatus}
          setSingleStatus={setSingleStatus}
        />

        {seats?.length > 0 && (
          <table>
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: rows }, (_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from({ length: columns }, (_, colIndex) => {
                    const seat = seats.find(
                      (seat) => seat.row === rowIndex + 1 && seat.column === colIndex + 1
                    );
                    return (
                      <td key={colIndex} className="seat-act-area">

                        <button
                          type="button"
                          className={`square-button ${seat?.status} ${seat?.isBooked || seat?.isTemporaryBooked ? 'booked' : ''}`}
                          onClick={() => {
                            if (seat?.status !== 'unavailable' && seat?.status !== 'stair' && !seat?.isBooked) {
                              handleSeatClickWrapper(seat?._id);
                            }
                          }}
                          style={{ fontSize: "8px", color: "white" }}
                          disabled={seat?.status === 'unavailable' || seat?.status === 'stair' || seat?.isBooked}
                        >

                          <Tooltip tooltipContent={seat && handleGetTooltipContent(seat)} />

                          {!seat?.isBooked && !seat?.isTemporaryBooked && <SeatIcon status={seat?.status} />}

                          {seat && seat?.isBooked && (
                            <FaRegBookmark color="gray" />
                          )}

                          {
                            seat && !seat?.isBooked && seat?.isTemporaryBooked &&
                            <FaRegClock color="gray" />
                          }

                        </button>

                      </td>
                    );
                  })}
                </tr>
              ))}

            </tbody>
          </table>
        )}
      </div>
    </div>

  );
}

export default UpdateShowseat;
