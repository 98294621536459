import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../../Context/AppContext";

import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";

import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateEventPrice from "./CreateShowsPrice";
import UpdateEventPrice from "./UpdateShowsPrice";

function ShowsPrice() {
  const { showsId } = useParams();

  const [showPrices, setShowPrices] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);


  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetShowsPrices() {
      const { data } = await axiosInstance.get(
        `events/shows/showsPrice/getAllShowsPriceByShow/${showsId}`
      );
      setShowPrices(data);
    }
    fetchAndSetShowsPrices();
  }, [toggleFetch, axiosInstance, showsId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Shows Prices (${showPrices?.length ? showPrices.length : 0} in total)`}</h1>
          </div>

          <CreateButton
            screenTopicSingular="Shows Price"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            {/* <CRUDth th="Event Name" /> */}
            <CRUDth th="Status" />
            <CRUDth th="Price" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {showPrices?.length > 0 &&
              showPrices?.map((showPrice) => (
                <CRUDTableRow key={showPrice._id}>
                  {/* <ShortTextCell text={showPrice?.event?.name} /> */}
                  <ShortTextCell text={showPrice?.status} />
                  <ShortTextCell text={showPrice?.price} />

                  <td className="action_button_cell">
                    {/* <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={crew._id}
                      setTargetID={setTargetID}
                    /> */}
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={showPrice._id}
                      setTargetID={setTargetID}
                    />

                    {/* <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={crew._id}
                      isArchive={crew?.isArchive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    /> */}
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Shows Price`) ||
            (showUpdateForm && `Update Shows Price`) ||
            (showDeleteSection && `Archive Shows Price`)
          }
        >
          {showCreateForm && (
            <CreateEventPrice
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              showsId={showsId}
            />
          )}
          {showUpdateForm && (
            <UpdateEventPrice
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              showsId={showsId}
            />
          )}

          {/* {showDeleteSection && (
            <DeleteItem
              api={MANAGE_BRANCH_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />

            <ArchiveItem
              api={"events/crews/archiveCrew/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )} */}
        </Modal>
      )}
      {/* 
      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Crew`}
        >
          <ViewCrew targetID={targetID} employee={employee} />
        </Modal>
      )} */}
    </>
  );
}

export default ShowsPrice;
