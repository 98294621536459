import { useContext, useEffect, useState } from "react";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import Modal from "../../../Partials/Elements/Modal/Modal";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ALL_EMPLOYESS_API } from "../../../../Utilities/APIs/APIs";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";

import SelectInput from './../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput';
import AppContext from "../../../../Context/AppContext";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";

function Employees() {
  const [employees, setEmployees] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);

  const [archive, setArchive] = useState(null);

  const { employee, setIsLoading } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEmployees() {
        setIsLoading(true)
      const { data } = await axiosInstance.get(`${ALL_EMPLOYESS_API}`);
      setEmployees(data);
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);

    }

    fetchAndSetEmployees();
  }, [employee.token, toggleFetch]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function handleClose() {
    setShowModal(false);
  }



  return (
    <>
      <div className="crud_holder">
        <div className="body_header">

        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Employees (${employees?.length > 0 ? employees?.length : 0} in total)`}</h1>
          </div>
          {/* <h1 className="heading">{`Employees (${employees?.length > 0 ? employees?.length : 0} in total)`}</h1>
          */}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Image" />
            <CRUDth th="Name" />
            <CRUDth th="Email Address" />
            <CRUDth th="Level" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {employees && employees?.map((e) => (
              <CRUDTableRow key={e._id}>
                <ImageCell imgSrc={e.dp} imgAlt={e.name} />
                <ShortTextCell text={e.name} />
                <ShortTextCell text={e.email} />
                <ShortTextCell text={e.level} />

                {employee.level === "admin" ? (
                  <td className="action_button_cell">
                    <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={e._id}
                      isArchive={e.isArchive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    />
                  </td>
                ) : (
                  ""
                )}
                {/* <td className="action_button_cell">
                                    <CRUDButton handleClick={() => {
                                        setTargetID(e._id)
                                        setShowModal(true)
                                        setShowDeleteSection(true)
                                    }}>
                                        <RiDeleteBin2Line />
                                    </CRUDButton>
                                </td> */}
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showDeleteSection && `Delete Invite`}
        >
          {showDeleteSection && (
            // <DeleteItem
            //   api={"deleteEmployee/"}
            //   triggerFetch={triggerFetch}
            //   targetID={targetID}
            //   employee={employee}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            // />

            <ArchiveItem
              api={"archiveEmployee/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default Employees;
