import { useEffect, useState } from "react";

import Form from "../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import TimeInput from "../../../Partials/Layouts/Forms/FormInputs/TimeInput/TimeInput";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";

function UpdateShows({ eventId, setShowUpdateForm, setShowModal, triggerFetch, targetID }) {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const axiosInstance = useAxiosInstance()
  const [halls, setHalls] = useState([])
  const [hall, setHall] = useState("")


  useEffect(() => {
    async function fetchAndSetHall() {
      const { data } = await axiosInstance.get("/theaters/halls")
      setHalls(data)
    }
    fetchAndSetHall()

    async function fetchAndSetShows() {
      const { data } = await axiosInstance.get('events/shows/getSingleShow/' + targetID)
      setStartTime(data.startTime)
      endTime && endTime !== '' && setEndTime(data.endTime)
      
      const formattedDate = data?.show?.date?.split('T')[0]
      setDate(formattedDate)
    }
    fetchAndSetShows()

  }, [axiosInstance, targetID])


  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("startTime", startTime);
    itemData.append("endTime", endTime);
    itemData.append('date', date)


    const response = await axiosInstance.patch("events/shows/updateShowDateTime/" + targetID, itemData)
    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch()
    }
  }

  console.log("date",date)


  return (
    <Form onSubmit={handleSubmit}>
      {/* <SelectInput value={hall} setState={setHall} label={"Select Hall"}>
        {
          halls && halls.length > 0 && halls?.map(hall =>
            <SelectOption key={hall._id} optionText={hall?.name} optionValue={hall?._id} />
          )
        }
      </SelectInput> */}
      <DateInput
        label={`Show Date`}
        value={ date}
        placeholder={`Event Show Date`}
        setState={setDate}
      />
      <TimeInput
        label={`Start Time`}
        value={startTime}
        placeholder={`Event Start Time`}
        setState={setStartTime}
      />
      <TimeInput
        label={`End Time`}
        value={endTime}
        placeholder={`Event End Time`}
        setState={setEndTime}
      />

      <FormSubmitButton text="Update Shows" />
    </Form>
  )
}

export default UpdateShows