import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { CSVLink } from "react-csv";


function SeatNameCsv({ showId, setShowSeatNameDownloadSection }) {

    const [seatNameCsvs, setSeatNameCsvs] = useState([])

    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetSeatNameCsv() {
            const { data } = await axiosInstance.get(`orders/getSeatnameCsvFromShow?showId=${showId}`)
            console.log("data")
            setSeatNameCsvs(data)
        }
        fetchAndSetSeatNameCsv()
    }, [showId])

    const processedOrdersCSVFromSeatName = seatNameCsvs?.length > 0 && seatNameCsvs?.map((order) => ({
        ...order
    }))

    const csvFromSeatNameheaders = [
        { label: "Seat Name", key: "seatName" },
        { label: "Customer Name", key: "customerName" },
        { label: "Customer Email", key: "customerEmail" },
        { label: "Customer Phone", key: "customerPhone" },
        { label: "Type", key: "type" },
        { label: "Price", key: "price" },

    ];

    const seatNameCsvReport = {
        filename: 'OrderSeatnameCsvFromShow.csv',
        headers: csvFromSeatNameheaders,
        data: processedOrdersCSVFromSeatName
    };

    return (

        <div className="delete_item">
            <div className="delete_content">
                <h1>Are you sure?</h1>
                <p>Please confirm you want to download seatname csv.</p>
                <div className="btn_container">

                    {seatNameCsvs?.length > 0 && <CSVLink {...seatNameCsvReport} onClick={() => setShowSeatNameDownloadSection(false)} className="download">Download</CSVLink>}

                    <button className='cancel'
                        onClick={() => {
                            setShowSeatNameDownloadSection(false);
                        }}
                    >Cancel</button>
                </div>
            </div>
        </div>

    )
}

export default SeatNameCsv