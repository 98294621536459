import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../Partials/Elements/BackButton/BackButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import Modal from "../../Partials/Elements/Modal/Modal";
import ViewDuplicateOrders from "./ViewDuplicateOrders/ViewDuplicateOrders";

function DuplicateOrders() {

    const [conflicts, setConflicts] = useState(null)
    const [uniqueOrders, setUniqueOrders] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const [showViewSection, setShowViewSection] = useState(false);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetDuplicateOrders() {
            const { data } = await axiosInstance.get(`orders/getDuplicateOrderSeats`);
            if (data) {
                setUniqueOrders(data.uniqueOrders);
                setConflicts(data.conflicts)
            }
        }
        fetchAndSetDuplicateOrders();
    }, [axiosInstance]);

    function handleClose() {
        setShowModal(false);
        setShowViewSection(false);
    }

    function handleConflictClick(orderId, conflictOrderId) {
        setTargetID({ mainOrderId: orderId, conflictOrderId });
        setShowModal(true);
        setShowViewSection(true);
    }


    return (
        <>
            <div className="crud_holder">
                <div className="body_header">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                        <h1 className="heading">{`Duplicate Orders (${uniqueOrders?.length} in total)`}</h1>
                    </div>
                </div>

                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th="Order Id" />
                        <CRUDth th="Conflicts" />
                    </CRUDTableHeader>
                    <tbody>
                        {
                            uniqueOrders?.length > 0 &&
                            uniqueOrders.map(order => {
                                const conflictsWithThisOrder = []
                                conflicts.forEach(conflict => {
                                    conflict.forEach(conflictOrder => {
                                        if (conflictOrder.order._id === order._id) {
                                            conflictsWithThisOrder.push(conflict)
                                        }
                                    })
                                });
                                let conflictOrdersArray = []
                                conflictsWithThisOrder.forEach(conflict => {
                                    conflict.forEach(o => {
                                        conflictOrdersArray = [...conflictOrdersArray, o]
                                    })
                                })
                                console.log("🚀 ~ DuplicateOrders ~ conflictOrdersArray:", conflictOrdersArray)
                                const allConflictOrderIds = conflictOrdersArray.map(c => c.order.orderId)
                                const conflictOrderIds = Array.from(new Set(allConflictOrderIds))

                                return (
                                    <CRUDTableRow key={order._id}>
                                        <ShortTextCell text={order.orderId} />

                                        <td>
                                            {
                                                conflictOrderIds.filter(o => o !== order.orderId).map(o => (
                                                    <button
                                                        style={{
                                                            marginRight: '5px',
                                                            cursor: 'pointer',
                                                            border: "1px solid var(--primary-color)",
                                                            color: "white",
                                                            padding: "5px"
                                                        }}
                                                        key={o}
                                                        onClick={() => handleConflictClick(order.orderId,o)}>{o}</button>
                                                ))
                                            }
                                        </td>

                                        {/* 
                                        {
                                            conflictOrderIds.filter(o => o !== order.orderId).map(o => (
                                                <p>{o}</p>
                                            ))
                                        } */}

                                    </CRUDTableRow>
                                )
                            })

                        }
                    </tbody>
                </CRUDTable>
            </div>

            {showModal && showViewSection && (
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={showViewSection && `View Duplicate Orders`}
                >
                    <ViewDuplicateOrders targetID={targetID} />
                </Modal>
            )}
        </>
    );
}

export default DuplicateOrders;
