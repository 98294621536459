import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../Partials/Layouts/Forms/Form";

function UpdateHall({
  targetID,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [name, setName] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetAds() {
      const { data } = await axiosInstance.get(
        "theaters/halls/getSingleHall/" + targetID
      );
      setName(data.name);
    }
    fetchAndSetAds();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);

    const response = await axiosInstance.patch(
      "theaters/halls/" + targetID,
      itemData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Hall Name`}
        value={name}
        placeholder={`Enter Hall Name`}
        setState={setName}
      />

      <FormSubmitButton text="Update Hall" />
    </Form>
  );
}

export default UpdateHall;
