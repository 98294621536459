import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { IMAGE_URL } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewHeroSlider({targetID}) {
  const [heroSlider, setHeroSlider] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetProductInfo() {
      const { data } = await axiosInstance.get(
        "websiteContents/heroSliders/getSingleHeroSlider/" + targetID
      );
      setHeroSlider(data.event);
    }
    fetchAndsetProductInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {heroSlider && (
         <>
         <h1>Event Name</h1>
         <p>{heroSlider?.name}</p>

         <h1>Description</h1>
         <p>{heroSlider.description}</p>

         <h1>Release Date</h1>
         <p>{new Date(heroSlider.releaseDate).toLocaleDateString()}</p>

         <h1>Organizer</h1>
         <p>{heroSlider.organizer}</p>

         <h1>Type</h1>
         <p>{heroSlider.type}</p>

         <h1>Duration</h1>
         <p>{heroSlider.duration}</p>

         <h1>Location</h1>
         <p>{heroSlider.location}</p>

         <h1>Card Image</h1>
         <Image imgLink={heroSlider.cardImage} imgAlt={"Card Front Image"} />

         <h1>Banner Image</h1>
         <Image imgLink={heroSlider.bannerImage} imgAlt={"Card Banner Image"} />
        
       </>
      )}
    </div>
  );
}

export default ViewHeroSlider;
